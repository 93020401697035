/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id                     = '#page__backup_options_edit';
        var __backup_option_id            = '#backup_option_id';
        var __btn_add_items               = '.js-action-add-items';
        var __btn_delete_item             = '.js-action-delete-item';
        var __btn_delete_temp_item        = '.js-action-delete-temp-item';
        var __btn_add_row                 = '.js-add-row';
        var __btn_save_row                = '.js-save-row';
        var __btn_cancel_missing_products = '.js-btn-cancel-missing-products';

        var __dropdown_company    = '.js-dropdown-company';
        var __input_amount        = '.js-input-amount';
        var __input_interval      = '.js-input-interval';
        var __input_interval_unit = '.js-input-interval-unit';
        var __input_upfront       = '.js-input-upfront';

        var __table_fuelcell_capex  = '#dt-table-fuelcell-capex';
        var __table_fuelcell_opex   = '#dt-table-fuelcell-opex';
        var __table_battery_capex   = '#dt-table-battery-capex';
        var __table_battery_opex    = '#dt-table-battery-opex';
        var __table_generator_capex = '#dt-table-generator-capex';
        var __table_generator_opex  = '#dt-table-generator-opex';

        var __table_fuelcell_capex_items_template  = '#dt-table-fuelcell_capex__template';
        var __table_fuelcell_opex_items_template   = '#dt-table-fuelcell_opex__template';
        var __table_battery_capex_items_template   = '#dt-table-battery_capex__template';
        var __table_battery_opex_items_template    = '#dt-table-battery_opex__template';
        var __table_generator_capex_items_template = '#dt-table-generator_capex__template';
        var __table_generator_opex_items_template  = '#dt-table-generator_opex__template';

        var __table_fuelcell_capex_items_pricelist = '#dt-table-fuelcell_capex__pricelist';
        var __table_fuelcell_opex_items_list       = '#dt-table-fuelcell_opex__list';
        var __table_battery_capex_items_list       = '#dt-table-battery_capex__list';
        var __table_battery_opex_items_list        = '#dt-table-battery_opex__list';
        var __table_generator_capex_items_list     = '#dt-table-generator_capex__list';
        var __table_generator_opex_items_list      = '#dt-table-generator_opex__list';

        var BACKUP_OPTION_ID        = $(__backup_option_id).val();
        var TOTALS                  = {};
        var COLUMNS_FUELCELL_CAPEX  = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'product_number',
                title    : 'Product number',
                orderable: false,
                width    : '120px',
                className: 'text-left'
            },
            {
                data     : 'name',
                title    : 'Name',
                orderable: false,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: false,
                className: 'text-center',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : null,
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                render   : function(data, type, row) {
                    return format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="fuelcell-capex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];
        var COLUMNS_FUELCELL_OPEX   = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'name',
                title    : 'Item',
                orderable: false,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: false,
                className: 'text-center',
                width    : '150px',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'interval',
                title    : 'Interval',
                orderable: false,
                className: 'text-center',
                width    : '140px',
                render   : function(data, type, row) {
                    var interval      = data;
                    var interval_unit = row.interval_unit;

                    var $monthSelected = interval_unit == 'month'
                        ? 'selected'
                        : '';

                    var $yearSelected = interval_unit == 'year'
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<div class="input-group input-group-sm input-group-40-60">';
                    $html += '  <input type="number" min="1" class="form-control js-input-interval" value="' + interval + '"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <select class="form-control js-input-interval-unit"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '    <option value="month" ' + $monthSelected + '>Month(s)</option>';
                    $html += '    <option value="year" ' + $yearSelected + '>Year(s)</option>';
                    $html += '  </select>';
                    $html += '</div>';

                    return $html;
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : 'is_up_front',
                title    : 'Paid up-front',
                orderable: false,
                className: 'text-center',
                width    : '100px',
                render   : function(data, type, row) {
                    var is_up_front  = data;
                    var $yesSelected = is_up_front == 1
                        ? 'selected'
                        : '';

                    var $noSelected = is_up_front == 0
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<select class="form-control fc-input-sm js-input-upfront"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <option value="1" ' + $yesSelected + '>Yes</option>';
                    $html += '  <option value="0" ' + $noSelected + '>No</option>';
                    $html += '</select>';

                    return $html;
                }
            },
            {
                data     : 'total',
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                width    : '120px',
                render   : function(data, type, row) {
                    return '<span class="total-per-item">' + format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code) + '</span>';
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="fuelcell-opex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];
        var COLUMNS_BATTERY_CAPEX   = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'name',
                title    : 'Name',
                orderable: true,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: true,
                className: 'text-center',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : null,
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                render   : function(data, type, row) {
                    return format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="battery-capex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];
        var COLUMNS_BATTERY_OPEX    = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'name',
                title    : 'Item',
                orderable: false,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: false,
                className: 'text-center',
                width    : '150px',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'interval',
                title    : 'Interval',
                orderable: false,
                className: 'text-center',
                width    : '140px',
                render   : function(data, type, row) {
                    var interval      = data;
                    var interval_unit = row.interval_unit;

                    var $monthSelected = interval_unit == 'month'
                        ? 'selected'
                        : '';

                    var $yearSelected = interval_unit == 'year'
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<div class="input-group input-group-sm input-group-40-60">';
                    $html += '  <input type="number" min="1" class="form-control js-input-interval" value="' + interval + '"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <select class="form-control js-input-interval-unit"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '    <option value="month" ' + $monthSelected + '>Month(s)</option>';
                    $html += '    <option value="year" ' + $yearSelected + '>Year(s)</option>';
                    $html += '  </select>';
                    $html += '</div>';

                    return $html;
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : 'is_up_front',
                title    : 'Paid up-front',
                orderable: false,
                className: 'text-center',
                width    : '100px',
                render   : function(data, type, row) {
                    var is_up_front  = data;
                    var $yesSelected = is_up_front == 1
                        ? 'selected'
                        : '';

                    var $noSelected = is_up_front == 0
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<select class="form-control fc-input-sm js-input-upfront"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <option value="1" ' + $yesSelected + '>Yes</option>';
                    $html += '  <option value="0" ' + $noSelected + '>No</option>';
                    $html += '</select>';

                    return $html;
                }
            },
            {
                data     : 'total',
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                width    : '120px',
                render   : function(data, type, row) {
                    return '<span class="total-per-item">' + format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code) + '</span>';
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="battery-opex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];
        var COLUMNS_GENERATOR_CAPEX = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'name',
                title    : 'Name',
                orderable: true,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: true,
                className: 'text-center',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : null,
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                render   : function(data, type, row) {
                    return format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="battery-capex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];
        var COLUMNS_GENERATOR_OPEX  = [
            {
                title  : 'ID',
                data   : 'id',
                visible: false
            },
            {
                data     : 'name',
                title    : 'Item',
                orderable: false,
                className: 'text-left'
            },
            {
                data     : 'price',
                title    : 'Price',
                orderable: false,
                className: 'text-center',
                width    : '150px',
                render   : function(data, type, row) {
                    return format_price(data, ' ' + row.backup_option.company.currency.code);
                }
            },
            {
                data     : 'interval',
                title    : 'Interval',
                orderable: false,
                className: 'text-center',
                width    : '140px',
                render   : function(data, type, row) {
                    var interval      = data;
                    var interval_unit = row.interval_unit;

                    var $monthSelected = interval_unit == 'month'
                        ? 'selected'
                        : '';

                    var $yearSelected = interval_unit == 'year'
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<div class="input-group input-group-sm input-group-40-60">';
                    $html += '  <input type="number" min="1" class="form-control js-input-interval" value="' + interval + '"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <select class="form-control js-input-interval-unit"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '    <option value="month" ' + $monthSelected + '>Month(s)</option>';
                    $html += '    <option value="year" ' + $yearSelected + '>Year(s)</option>';
                    $html += '  </select>';
                    $html += '</div>';

                    return $html;
                }
            },
            {
                data     : 'amount',
                title    : 'Amount',
                orderable: false,
                className: 'text-center',
                width    : '50px',
                render   : function(data, type, row) {
                    return '<input type="number" min="1" class="form-control fc-input-sm text-center fc-block js-input-amount" ' +
                           'value="' + data + '" data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '" />';
                }
            },
            {
                data     : 'is_up_front',
                title    : 'Paid up-front',
                orderable: false,
                className: 'text-center',
                width    : '100px',
                render   : function(data, type, row) {
                    var is_up_front  = data;
                    var $yesSelected = is_up_front == 1
                        ? 'selected'
                        : '';

                    var $noSelected = is_up_front == 0
                        ? 'selected'
                        : '';

                    var $html = '';
                    $html += '<select class="form-control fc-input-sm js-input-upfront"' +
                             ' data-backup-option-id="' + row.backup_options_id + '" data-id="' + row.id + '">';
                    $html += '  <option value="1" ' + $yesSelected + '>Yes</option>';
                    $html += '  <option value="0" ' + $noSelected + '>No</option>';
                    $html += '</select>';

                    return $html;
                }
            },
            {
                data     : 'total',
                title    : 'Total',
                orderable: false,
                className: 'text-center success',
                width    : '120px',
                render   : function(data, type, row) {
                    return '<span class="total-per-item">' + format_price(parseFloat(row.price_original * row.amount)
                        .toFixed(0), ' ' + row.backup_option.company.currency.code) + '</span>';
                }
            },
            {
                data     : 'actions',
                orderable: false,
                className: 'text-right',
                width    : '35px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.backup_options_id + '/items/' + row.id + '/delete" class="btn btn-danger btn-sm js-action-delete-item" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete item" ' +
                                 'data-type="battery-opex">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            var self = this;

            $.fn.dataTableExt.sErrMode = "console";

            __initTable();
            __initTableListsItems();
            __initTableTemplates();

            $(__body_id).on('click', '.nav-tabs-items a', function(e) {
                $.ajax({
                    url : '/backup-options/tab-type/' + $(this).attr('href').split('#tab-')[ 1 ],
                    type: 'post',
                    data: {
                        _token: window.TOKEN
                    }
                });
            });

            $(__body_id).on('change', __dropdown_company, function(e) {
                e.preventDefault();

                var is_edit = ($(this).closest('form').find('[name="_method"]').val() == "put");

                if (!is_edit) {
                    return;
                }

                var original_company_id = +$(this).data('original-value');
                var company_id          = +$(this).val();
                var backup_option_id    = $('#backup_option_id').val();

                if (original_company_id == company_id) {
                    return;
                }

                $.ajax({
                    url       : '/backup-options/check-company',
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token          : window.TOKEN,
                        backup_option_id: backup_option_id,
                        company_id      : company_id
                    },
                    beforeSend: function() {
                        window.addLoader(null, 'Checking the Price List...');
                    },
                    complete  : function() {
                        setTimeout(function() {
                            window.removeLoader();
                        }, 500);
                    },
                    success   : function(response) {
                        $('#dt-table-missing-product-numbers tbody').html('');

                        if (response.status) {
                            response.missed_items.forEach(function(item) {
                                $('#dt-table-missing-product-numbers tbody').append(
                                    '<tr>' +
                                    '<td>' + item.product_number + '</td>' +
                                    '<td>' + item.component + '</td>' +
                                    '<td>' + item.category + '</td>' +
                                    '</tr>'
                                );
                            });

                            $('#modalMissingProductNumbers').modal('show');
                        }
                    }
                });
            });

            $(__body_id).on('click', __btn_cancel_missing_products, function(e) {
                e.preventDefault();

                var $this = $(this);

                $('.js-dropdown-company').val($('.js-dropdown-company').data('original-value'));

                $('#modalMissingProductNumbers').modal('hide');
            });

            $(__body_id).on('click', __btn_add_items, function(e) {
                e.preventDefault();

                var $this  = $(this);
                var $modal = $this.closest('.modal');

                var id        = $modal.data('model-id');
                var types     = $this.data('type');
                var type      = types.split('__')[ 0 ];
                var item_type = types.split('__')[ 1 ];

                var item_ids = $modal.find('[name="item"]:checkbox:checked').map(function() {
                    return this.value;
                }).get();

                if (!item_ids.length) {
                    $modal.modal('hide');

                    return;
                }

                $.ajax({
                    url       : '/backup-options/' + id + '/items',
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token   : window.TOKEN,
                        type     : type,
                        item_type: item_type,
                        item_ids : item_ids
                    },
                    beforeSend: function() {
                    },
                    success   : function(response) {
                        $modal.modal('hide');

                        $('#dt-table-' + type.replace('_', '-')).DataTable().draw('page');
                    }
                });
            });

            $(__body_id).on('click', __btn_delete_item, function(e) {
                e.preventDefault();

                if (!confirm('Are you sure?')) {
                    return;
                }

                var $this = $(this);
                var type  = $this.data('type');
                var url   = $this.attr('href');
                var table = '#dt-table-' + type;

                $.ajax({
                    url       : url,
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token: window.TOKEN
                    },
                    beforeSend: function() {
                        $('.dataTables_processing', $(table).closest('.dataTables_wrapper')).show();
                    },
                    success   : function(response) {
                        $(table).DataTable().draw('page');
                    }
                });
            });

            $(__body_id).on('click', __btn_save_row, function(e) {
                e.preventDefault();

                var $this     = $(this);
                var $table    = $this.closest('table');
                var $tfoot    = $table.find('tfoot');
                var $row_temp = $table.find('tr.row-temp');

                var data = {
                    _token: window.TOKEN,
                    type  : $this.data('target')
                };

                data.name   = $row_temp.find('[name="temp[name]"]').val();
                data.price  = $row_temp.find('[name="temp[price]"]').val();
                data.amount = $row_temp.find('[name="temp[amount]"]').val();

                switch (data.type.toLowerCase()) {
                    case window.FUELCELL_CAPEX:
                        data.product_number = $row_temp.find('[name="temp[product_number]"]').val();
                        break;

                    case window.FUELCELL_OPEX:
                    case window.BATTERY_OPEX:
                    case window.GENERATOR_OPEX:
                        data.interval      = $row_temp.find('[name="temp[interval]"]').val();
                        data.interval_unit = $row_temp.find('[name="temp[interval_unit]"]').val();
                        data.is_up_front   = $row_temp.find('[name="temp[is_up_front]"]').val();
                        break;
                }

                $.ajax({
                    url       : '/backup-options/' + BACKUP_OPTION_ID + '/add-item',
                    type      : 'post',
                    dataType  : 'json',
                    data      : data,
                    beforeSend: function() {
                        window.addLoader();
                    },
                    success   : function(response) {
                        $table.DataTable().draw('page');

                        $this.addClass('hidden');
                        $tfoot.find(__btn_add_row).removeClass('hidden');
                    },
                    error     : function(xhr, ajaxOptions, thrownError) {
                        var response = xhr.responseJSON;

                        var fields = Object.keys(data);

                        for (var i in fields) {
                            if (!fields.hasOwnProperty(i)) continue;
                            if (!response[ fields[ i ] ]) continue;

                            var field   = fields[ i ];
                            var message = response[ fields[ i ] ][ 0 ];

                            var $input     = $row_temp.find('[name="temp[' + field + ']"]');
                            var $formGroup = $input.closest('.form-group');
                            var $helpBlock = $formGroup.find('.help-block');

                            if (!$helpBlock.length) {
                                $formGroup.append('<p class="help-block col-sm-10 col-sm-offset-2"></p>');
                                $helpBlock = $formGroup.find('.help-block');
                            }

                            $formGroup.addClass('has-error');
                            $helpBlock.html(message);
                        }
                    },
                    complete  : function() {
                        setTimeout(function() {
                            window.removeLoader();
                        }, 500);
                    }
                });
            });

            $(__body_id).on('click', __btn_add_row, function(e) {
                e.preventDefault();

                var $this = $(this);
                var type  = $this.data('target');

                $this.addClass('hidden');
                $this.closest('tfoot').find(__btn_save_row).removeClass('hidden');

                var $row = '<tr role="row" class="odd row-temp active" data-type="' + type + '">';

                var symbol   = '$';
                var position = 'left';

                switch (type) {
                    case window.FUELCELL_CAPEX:
                        symbol   = $(__table_fuelcell_capex).data('currency-symbol');
                        position = $(__table_fuelcell_capex).data('currency-position');
                        break;

                    case window.FUELCELL_OPEX:
                        symbol   = $(__table_fuelcell_opex).data('currency-symbol');
                        position = $(__table_fuelcell_opex).data('currency-position');
                        break;

                    case window.BATTERY_CAPEX:
                        symbol   = $(__table_battery_capex).data('currency-symbol');
                        position = $(__table_battery_capex).data('currency-position');
                        break;

                    case window.BATTERY_OPEX:
                        symbol   = $(__table_battery_opex).data('currency-symbol');
                        position = $(__table_battery_opex).data('currency-position');
                        break;

                    case window.GENERATOR_CAPEX:
                        symbol   = $(__table_generator_capex).data('currency-symbol');
                        position = $(__table_generator_capex).data('currency-position');
                        break;

                    case window.GENERATOR_OPEX:
                        symbol   = $(__table_generator_opex).data('currency-symbol');
                        position = $(__table_generator_opex).data('currency-position');
                        break;
                }

                switch (type) {
                    case window.FUELCELL_CAPEX:
                        $row += '<td class="text-left top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="text" class="form-control fc-input-sm text-left fc-block" name="temp[product_number]">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-left top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="text" class="form-control fc-input-sm text-left fc-block" name="temp[name]">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<div class="input-group input-group-sm">' +
                                (position == 'left'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '<input class="form-control" name="temp[price]" data-keyup="decimal" type="text" value="" style="min-width: 100px!important;">' +
                                (position == 'right'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '</div>' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="number" min="1" name="temp[amount]" class="form-control fc-input-sm text-center fc-block" value="1">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center">' +
                                (position == 'left'
                                    ? symbol
                                    : '') +
                                '<value class="total-row">0.00</value>' +
                                (position == 'right'
                                    ? symbol
                                    : '') +
                                '</td>';

                        $row += '<td class="text-right top-align-imp">' +
                                '<a class="btn btn-danger btn-sm js-action-delete-temp-item" title="Delete item"><i class="fa fa-times"></i></a>' +
                                '</td>';
                        break;

                    case window.BATTERY_CAPEX:
                    case window.GENERATOR_CAPEX:
                        $row += '<td class="text-left top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="text" class="form-control fc-input-sm text-left fc-block" name="temp[name]">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<div class="input-group input-group-sm">' +
                                (position == 'left'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '<input class="form-control" name="temp[price]" data-keyup="decimal" type="text" value="" style="min-width: 100px!important;">' +
                                (position == 'right'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '</div>' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="number" min="1" name="temp[amount]" class="form-control fc-input-sm text-center fc-block" value="1">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center">' +
                                (position == 'left'
                                    ? symbol
                                    : '') +
                                '<value class="total-row">0.00</value>' +
                                (position == 'right'
                                    ? symbol
                                    : '') +
                                '</td>';

                        $row += '<td class="text-right top-align-imp">' +
                                '<a class="btn btn-danger btn-sm js-action-delete-temp-item" title="Delete item"><i class="fa fa-times"></i></a>' +
                                '</td>';
                        break;

                    case window.FUELCELL_OPEX:
                    case window.BATTERY_OPEX:
                    case window.GENERATOR_OPEX:
                        $row += '<td class="text-left top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="text" class="form-control fc-input-sm text-left fc-block" name="temp[name]">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<div class="input-group input-group-sm">' +
                                (position == 'left'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '<input class="form-control" name="temp[price]" data-keyup="decimal" type="text" value="" style="min-width: 100px!important;">' +
                                (position == 'right'
                                    ? '<span class="input-group-addon">' + symbol + '</span>'
                                    : '') +
                                '</div>' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<div class="input-group input-group-sm input-group-40-60">' +
                                '<input type="number" min="1" class="form-control" value="1" name="temp[interval]">' +
                                '<select class="form-control" name="temp[interval_unit]">' +
                                '<option value="month">Month(s)</option>' +
                                '<option value="year">Year(s)</option>' +
                                '</select>' +
                                '</div>' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<input type="number" min="1" name="temp[amount]" class="form-control fc-input-sm text-center fc-block" value="1">' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center top-align-imp">' +
                                '<div class="form-group fc-block">' +
                                '<select class="form-control fc-input-sm" name="temp[is_up_front]">' +
                                '<option value="1">Yes</option>' +
                                '<option value="0">No</option>' +
                                '</select>' +
                                '</div>' +
                                '</td>';

                        $row += '<td class="text-center">' +
                                (position == 'left'
                                    ? symbol
                                    : '') +
                                '<value class="total-row">0.00</value>' +
                                (position == 'right'
                                    ? symbol
                                    : '') +
                                '</td>';

                        $row += '<td class="text-right top-align-imp">' +
                                '<a class="btn btn-danger btn-sm js-action-delete-temp-item" title="Delete item"><i class="fa fa-times"></i></a>' +
                                '</td>';
                        break;
                }

                $row += '</tr>';

                switch (type) {
                    case window.FUELCELL_CAPEX:
                        $(__table_fuelcell_capex).find('tbody').append($row);
                        break;

                    case window.FUELCELL_OPEX:
                        $(__table_fuelcell_opex).find('tbody').append($row);
                        break;

                    case window.BATTERY_CAPEX:
                        $(__table_battery_capex).find('tbody').append($row);
                        break;

                    case window.BATTERY_OPEX:
                        $(__table_battery_opex).find('tbody').append($row);
                        break;

                    case window.GENERATOR_CAPEX:
                        $(__table_generator_capex).find('tbody').append($row);
                        break;

                    case window.GENERATOR_OPEX:
                        $(__table_generator_opex).find('tbody').append($row);
                        break;
                }
            });

            $(__body_id).on('click', __btn_delete_temp_item, function(e) {
                e.preventDefault();

                var $this = $(this);

                $this.closest('table').find('tfoot').find(__btn_save_row).addClass('hidden');
                $this.closest('table').find('tfoot').find(__btn_add_row).removeClass('hidden');
                $this.closest('tr').remove();
            });

            $(__body_id).on('keyup change', '[name="temp[amount]"], [name="temp[price]"]', function(e) {
                var $this      = $(this);
                var $row       = $this.closest('tr');
                var $total_row = $row.find('value.total-row');

                var amount = $row.find('[name="temp[amount]"]').val();
                var price  = $row.find('[name="temp[price]"]').val();

                $total_row.html((amount * price).toFixed(0));
            });

            $(__body_id)
                .on('keyup change', __input_amount + ',' + __input_interval + ',' + __input_interval_unit + ',' + __input_upfront, function(e) {
                    e.preventDefault();

                    var $this                = $(this);
                    var $row                 = $this.closest('tr');
                    var $input_amount        = $row.find(__input_amount);
                    var $input_interval      = $row.find(__input_interval);
                    var $input_interval_unit = $row.find(__input_interval_unit);
                    var $input_is_up_front   = $row.find(__input_upfront);

                    var id               = $this.data('id');
                    var backup_option_id = $this.data('backup-option-id');

                    $.ajax({
                        url     : '/backup-options/' + backup_option_id + '/items/' + id + '/edit',
                        type    : 'post',
                        dataType: 'json',
                        data    : {
                            _token       : window.TOKEN,
                            amount       : $input_amount.val(),
                            interval     : $input_interval.val(),
                            interval_unit: $input_interval_unit.val(),
                            is_up_front  : $input_is_up_front.val()
                        },
                        success : function(response) {
                            $this.closest('table').DataTable().draw('page');
                        }
                    });
                });
        };

        var __initTableModal = function(table) {
            $(table).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });
        };

        var __initTable = function() {
            __initTableBase(__table_fuelcell_capex, __body_id + __table_fuelcell_capex, COLUMNS_FUELCELL_CAPEX, 'fuelcell_capex');
            __initTableBase(__table_fuelcell_opex, __body_id + __table_fuelcell_opex, COLUMNS_FUELCELL_OPEX, 'fuelcell_opex');
            __initTableBase(__table_battery_capex, __body_id + __table_battery_capex, COLUMNS_BATTERY_CAPEX, 'battery_capex');
            __initTableBase(__table_battery_opex, __body_id + __table_battery_opex, COLUMNS_BATTERY_OPEX, 'battery_opex');
            __initTableBase(__table_generator_capex, __body_id + __table_generator_capex, COLUMNS_GENERATOR_CAPEX, 'generator_capex');
            __initTableBase(__table_generator_opex, __body_id + __table_generator_opex, COLUMNS_GENERATOR_OPEX, 'generator_opex');
        };

        var __initTableListsItems = function() {
            __initTableModal(__table_fuelcell_capex_items_pricelist);
            __initTableModal(__table_fuelcell_opex_items_list);
            __initTableModal(__table_battery_capex_items_list);
            __initTableModal(__table_battery_opex_items_list);
            __initTableModal(__table_generator_capex_items_list);
            __initTableModal(__table_generator_opex_items_list);
        };

        var __initTableTemplates = function() {
            __initTableModal(__table_fuelcell_capex_items_template);
            __initTableModal(__table_fuelcell_opex_items_template);
            __initTableModal(__table_battery_capex_items_template);
            __initTableModal(__table_battery_opex_items_template);
            __initTableModal(__table_generator_capex_items_template);
            __initTableModal(__table_generator_opex_items_template);
        };

        var __initTableBase = function(table, length_key, columns, type) {
            $(table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length(length_key, len);
                })
                .on('page.dt', function() {
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length(length_key),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    "order"       : [ [ 0, 'asc' ] ],
                    "columns"     : columns,
                    initComplete  : function() {
                        var dt = this;

                        if (!$(this).find('tfoot').length) {
                            $(this).append('<tfoot></tfoot>');
                        }

                        if (!$(this).find('tfoot').find('.row-filter').length) {
                            var $footer = '<tr class="row-total">' + __getFooter(dt, type) + '</tr>';

                            $(this).find('tfoot').append($footer);

                            setTimeout(function() {
                                __calcTotal(dt, type);
                            }, 100);
                        }
                    },
                    drawCallback  : function(settings) {
                        var self = this;

                        setTimeout(function() {
                            __calcTotal(self, type);
                        }, 100);
                    },
                    ajax          : function(data, callback, settings) {
                        data.type = type;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);

                                TOTALS[ type ] = response.total;
                            }
                        });
                    }
                });
        };

        var __calcTotal = function(dt, type) {
            dt.find('.table-total value').html(TOTALS[ type ]);
        };

        var __getFooter = function(dt, type) {
            var $html = '';

            switch (type) {
                case window.FUELCELL_CAPEX:
                    $html += '<td colspan="4" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsfuelcell_capex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsfuelcell_capex__pricelist">' +
                             '<i class="fa fa-list"></i> Select from price list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a>';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a>';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong success">';
                    $html += '<value>0.00</value>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;

                case window.FUELCELL_OPEX:
                    $html += '<td colspan="5" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsfuelcell_opex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsfuelcell_opex__list">' +
                             '<i class="fa fa-list"></i> Select from list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a> ';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a> ';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong">';
                    $html += '<span class="hidden"><value>0.00</value></span>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;

                case window.BATTERY_CAPEX:
                    $html += '<td colspan="3" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsbattery_capex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsbattery_capex__list">' +
                             '<i class="fa fa-list"></i> Select from list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a> ';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a> ';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong success">';
                    $html += '<value>0.00</value>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;

                case window.BATTERY_OPEX:
                    $html += '<td colspan="5" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsbattery_opex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsbattery_opex__list">' +
                             '<i class="fa fa-list"></i> Select from list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a> ';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a> ';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong">';
                    $html += '<span class="hidden">$<value>0.00</value></span>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;

                case window.GENERATOR_CAPEX:
                    $html += '<td colspan="3" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsgenerator_capex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsgenerator_capex__list">' +
                             '<i class="fa fa-list"></i> Select from list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a> ';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a> ';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong success">';
                    $html += '<value>0.00</value>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;

                case window.GENERATOR_OPEX:
                    $html += '<td colspan="5" class="text-left">';
                    $html += '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalItemsgenerator_opex__template">' +
                             '<i class="fa fa-folder"></i> Select from templates' +
                             '</a> ';
                    $html += '<a class="btn btn-primary btn-sm" data-toggle="modal" data-target="#modalItemsgenerator_opex__list">' +
                             '<i class="fa fa-list"></i> Select from list' +
                             '</a> ';
                    $html += '<a class="btn btn-warning btn-sm js-add-row" data-target="' + type + '">' +
                             '<i class="fa fa-plus"></i> Manually add' +
                             '</a> ';
                    $html += '<a class="btn btn-info btn-sm js-save-row hidden" data-target="' + type + '">' +
                             '<i class="fa fa-save"></i> Save row' +
                             '</a>';
                    $html += '</td>';

                    $html += '<td class="text-center table-total text-strong">';
                    $html += '<span class="hidden"><value>0.00</value></span>';
                    $html += '</td>';

                    $html += '<td class="text-right">';
                    $html += '</td>';
                    break;
            }

            return $html;
        };
    }).init();
})(jQuery);