/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__products_index';
        var __table   = '#dt-table';

        var SEARCH_ROW = {};
        var COLUMNS    = [
            {
                data        : 'product_number',
                title       : 'Product number',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                render      : function(data, type, row) {
                    return '<span data-toggle="product-hover" data-id="' + row.id + '" data-product-number="' + row.product_number + '">' + data + '</span>';
                }
            },
            {
                data        : 'component',
                title       : 'Component',
                orderable   : true,
                className   : 'text-left',
                customsearch: true
            },
            // {
            //     data        : 'category',
            //     title       : 'Category',
            //     orderable   : true,
            //     className   : 'text-center',
            //     customsearch: true
            // },
            {
                data        : null,
                title       : 'State',
                orderable   : false,
                className   : 'text-center',
                customsearch: false,
                render      : function(data, type, row) {
                    var $html = '';

                    if (row.image_file) {
                        $html += '<i class="fa fa-picture-o" aria-hidden="true"></i> ';
                    } else {
                        $html += '<i class="fa fa-picture-o fc-text-muted" aria-hidden="true"' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="No image"></i> ';
                    }

                    if (row.pdf_file) {
                        $html += '<i class="fa fa-file-pdf-o" aria-hidden="true"></i> ';
                    } else {
                        $html += '<i class="fa fa-file-pdf-o fc-text-muted" aria-hidden="true"' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="No PDF"></i> ';
                    }

                    if (row.description.replace(/(<([^>]+)>)/ig, "")) {
                        $html += '<i class="fa fa-align-left" aria-hidden="true"></i>';
                    } else {
                        $html += '<i class="fa fa-align-left fc-text-muted" aria-hidden="true"' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="No description"></i>';
                    }

                    return $html;
                }
            },
            {
                data        : 'id',
                title       : '',
                orderable   : false,
                className   : 'text-right',
                customsearch: false,
                width       : '25px',
                render      : function(data, type, row) {
                    var $html = '';

                    $html += ' <a href="/products/' + data + '/edit" class="btn btn-default btn-sm" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit">' +
                             '<i class="fa fa-pencil"></i>' +
                             '</a>';

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            COLUMNS.forEach(function(element, index) {
                if (element.customsearch) {
                    SEARCH_ROW[ element.data ] = '';
                }
            });

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            __initTable();
        };

        var __initTable = function() {
            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length(__body_id, len);
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-6"i>' +
                                    '<"col-md-6 text-right"<"toolbar">l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length(__body_id),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    "order"       : [ [ 1, 'asc' ] ],
                    "columns"     : COLUMNS,
                    initComplete  : function() {
                        initToolbarFilters();

                        if (!$(this).find('thead').find('.row-filter').length) {
                            var $rowFilter = '<tr class="row-filter">';

                            COLUMNS.forEach(function(element, index) {
                                $rowFilter += '<td>';

                                if (element.customsearch) {
                                    $rowFilter += '<input type="text" class="form-control fc-input-sm fc-block js-search-field" data-field="' + element.data + '">';
                                }

                                $rowFilter += '</td>';
                            });

                            $rowFilter += '</tr>';

                            $(this).find('thead').append($rowFilter);
                        }
                    },
                    ajax          : function(data, callback, settings) {
                        data.search_row = SEARCH_ROW;
                        data.filter_row = window.FILTER_ROW;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    }
                });

            $(__body_id).on('keyup', __table + ' thead .row-filter .js-search-field', function(e) {
                __collectSearches();
            });
        };

        var __collectSearches = function() {
            $(__table + ' thead .row-filter .js-search-field').each(function() {
                var $input = $(this);

                SEARCH_ROW[ $input.data('field') ] = $input.val().trim();
            });

            setTimeout(function() {
                $(__table).DataTable().draw()
            }, 100);
        };

    }).init();
})(jQuery);