/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__price_list_edit';
        var __form    = '#form-edit-price-list';

        var __field_rate                = __form + ' [name="rate"]';
        var __field_discount            = __form + ' [name="discount"]';
        var __field_price               = __form + ' [name^="price_list_items["][name$="][price]"]';
        var __field_individual_discount = __form + ' [name^="price_list_items["][name$="][discount]"]';

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $(__field_rate).on('keypress', __numericInput);
            $(__field_discount).on('keypress', __numericInput);
            $(__field_price).on('keypress', __numericInput);
            $(__field_individual_discount).on('keypress', __numericInput);
        };

        /**
         ***************************************************************************
         * Format price input
         *
         * Allow only `delete`, 48-57 (0-9) buttons and one point
         ***************************************************************************
         */
        var __numericInput = function(e) {
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }
        };

    }).init();

})(jQuery);