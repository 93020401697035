/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__backup_options_index';
        var __table   = '#dt-table';

        var SEARCH_ROW = {};
        var COLUMNS    = [
            {
                data        : 'company_name',
                title       : 'Company Name',
                orderable   : true,
                className   : 'text-left',
                customsearch: true,
                width       : '250px'
            },
            {
                data        : 'name',
                title       : 'Name',
                orderable   : true,
                className   : 'text-left',
                customsearch: true,
                width       : '250px',
                render      : function(data, type, row) {
                    return data +
                           '<div class="inline pull-right" data-container="body" data-trigger="hover" ' +
                           'data-toggle="popover" data-placement="bottom" data-html="true" data-content="' + nl2br(row.description) + '">' +
                           '<i class="fa fa-info-circle"></i>' +
                           '</div>';
                }
            },
            {
                data        : 'backup_hours',
                title       : 'Backup',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                width       : '90px',
                render      : function(data, type, row) {
                    return data + ' Hrs';
                }
            },
            {
                data        : 'load_requirement',
                title       : 'Load Requirement',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                width       : '120px',
                render      : function(data, type, row) {
                    return data + 'kW';
                }
            },
            {
                data        : 'installation_type_label',
                title       : 'Installation Type',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                width       : '130px'
            },
            {
                data        : 'load_type_label',
                title       : 'Load Type',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                width       : '100px'
            },
            {
                data        : '',
                orderable   : false,
                className   : 'text-right col-actions-200',
                customsearch: false,
                width       : '210px',
                render      : function(data, type, row) {
                    var $html = '';

                    if (window.PERMISSIONS.backup_option__view) {
                        $html += ' <a href="/backup-options/' + row.id + '/view" class="btn btn-success btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Stats">' +
                                 '<i class="fa fa-bar-chart"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.backup_option__create) {
                        $html += ' <a href="/backup-options/' + row.id + '/clone" class="btn btn-info btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Clone Backup Option">' +
                                 '<i class="fa fa-clone"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.backup_option__edit) {
                        $html += ' <a href="/backup-options/' + row.id + '/edit" class="btn btn-default btn-sm" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit Backup Option">' +
                                 '<i class="fa fa-pencil"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.backup_option__delete) {
                        $html += ' <a href="/backup-options/' + row.id + '/delete" class="btn btn-danger btn-sm" ' +
                                 'data-method="post" data-confirm="Are you sure?" data-token="' + window.TOKEN + '" >' +
                                 '<i class="fa fa-times" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete Backup Option"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            COLUMNS.forEach(function(element, index) {
                if (element.customsearch) {
                    SEARCH_ROW[ element.data ] = '';
                }
            });

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            __initTable();
        };

        var __initTable = function() {
            if (!!parseInt(window.ROLE_IS_USER)) {
                COLUMNS.shift();
            }

            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length(__body_id, len);
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-4"i>' +
                                    '<"col-md-8 text-right"<"toolbar">l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length(__body_id),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    "order"       : [ [ 0, 'asc' ] ],
                    "columns"     : COLUMNS,
                    initComplete  : function() {
                        initToolbarFilters();

                        if (!$(this).find('thead').find('.row-filter').length) {
                            var $rowFilter = '<tr class="row-filter">';

                            COLUMNS.forEach(function(element, index) {
                                $rowFilter += '<td>';

                                if (element.customsearch) {
                                    $rowFilter += '<input type="text" class="form-control fc-input-sm fc-block js-search-field" data-field="' + element.data + '">';
                                }

                                $rowFilter += '</td>';
                            });

                            $rowFilter += '</tr>';

                            $(this).find('thead').append($rowFilter);
                        }
                    },
                    ajax          : function(data, callback, settings) {
                        data.search_row = SEARCH_ROW;
                        data.filter_row = window.FILTER_ROW;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    }
                });

            $(__body_id).on('keyup', __table + ' thead .row-filter .js-search-field', function(e) {
                __collectSearches();
            });
        };

        var __collectSearches = function() {
            $(__table + ' thead .row-filter .js-search-field').each(function() {
                var $input = $(this);

                SEARCH_ROW[ $input.data('field') ] = $input.val().trim();
            });

            setTimeout(function() {
                $(__table).DataTable().draw()
            }, 100);
        };

    }).init();

})(jQuery);