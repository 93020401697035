window.FUELCELL_CAPEX  = 'fuelcell_capex';
window.FUELCELL_OPEX   = 'fuelcell_opex';
window.BATTERY_CAPEX   = 'battery_capex';
window.BATTERY_OPEX    = 'battery_opex';
window.GENERATOR_CAPEX = 'generator_capex';
window.GENERATOR_OPEX  = 'generator_opex';

new Clipboard('.btn');

Array.prototype.clean = function(deleteValue) {
    for (var i = 0; i < this.length; i++) {
        if (this[ i ] == deleteValue) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
};

function show_error(msg) {
    $.notify({
        message: msg || 'Oh snap! Change a few things up and try submitting again.',
        icon   : 'fa fa-exclamation-triangle'
    }, {
        type: 'danger'
    });
}

function show_info(msg) {
    $.notify({
        message: msg || 'Heads up!',
        icon   : 'fa fa-info'
    }, {
        type: 'info'
    });
}

function show_warning(msg) {
    $.notify({
        message: msg || 'Heads up!',
        icon   : 'fa fa-exclamation-triangle'
    }, {
        type: 'warning'
    });
}

function show_success(msg) {
    $.notify({
        message: msg || 'Well done!',
        icon   : 'fa fa-check'
    }, {
        type: 'success'
    });
}

function save_step(url, data) {
    $.ajax({
        url       : url,
        type      : 'post',
        dataType  : 'json',
        data      : {
            _token: Laravel.csrfToken,
            data  : data
        },
        beforeSend: function() {
            $('#modal-loader').modal('show');
        },
        success   : function(response) {
            setTimeout(function() {
                if (response.status) {
                    window.location.reload();
                } else {
                    $('#modal-loader').modal('hide');

                    show_error(response.message);
                }
            }, 500);
        }
    });
}

function set_cookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires     = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function get_cookie(cname) {
    var name = cname + "=";
    var ca   = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[ i ];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function set_page_length(page, length) {
    set_cookie('page_length_' + page, length, 365);
}

function get_page_length(page) {
    return get_cookie('page_length_' + page) || 25;
}

function intval(i) {
    return typeof i === 'string'
        ? i.replace(/[\$,]/g, '') * 1
        : typeof i === 'number'
               ? i
               : 0;
}

function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined')
        ? '<br />'
        : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function dollar(value, fractionalDigits) {
    var isNegative = value < 0
        ? '-'
        : '';

    var abs = Math.abs(value);

    return isNegative + '$' + (fractionalDigits
        ? abs.toFixed(fractionalDigits)
        : abs);
}

function initToolbarFilters() {
    var $filters_row = $('#filters_row');
    var $toolbar     = $('#dt-table_wrapper .toolbar');

    if (!$filters_row.length) {
        console.info('filters_row is undefined');

        return;
    }

    if (!$toolbar.length) {
        console.info('toolbar is undefined');

        return;
    }

    var $html_filters = '';

    var filters = JSON.parse($filters_row.val());

    if (filters.length) {
        for (var i in filters) {
            if (!filters.hasOwnProperty(i)) continue;

            var filter = filters[ i ];

            $html_filters += filter.label;
            $html_filters += '<select class="form-control input-sm" data-field="' + filter.field + '">';
            $html_filters += '<option value="0" selected>All</option>';

            var listIds = Object.keys(filter.list);

            if (listIds.length) {
                for (var j in listIds) {
                    if (!listIds.hasOwnProperty(j)) continue;

                    var id    = listIds[ j ];
                    var label = filter.list[ id ];

                    $html_filters += '<option value="' + id + '" ' + (filter.active && id == filter.active
                        ? 'selected'
                        : '') + '>' + label + '</option>';
                }
            }

            $html_filters += '</select>';
        }
    }

    $toolbar.html($html_filters);
}

window.FILTER_ROW = {};

$('body').on('change', '.toolbar .form-control', function(e) {
    e.preventDefault();

    $('.toolbar .form-control').each(function() {
        var $input = $(this);

        window.FILTER_ROW[ $input.data('field') ] = $input.val().trim();
    });

    setTimeout(function() {
        $('#dt-table').DataTable().draw();
    }, 100);
});

$(document).on('change', ':file', function() {
    var input    = $(this),
        numFiles = input.get(0).files
            ? input.get(0).files.length
            : 1,
        label    = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', [ numFiles, label ]);
});

$(document).ready(function() {
    $(':file').on('fileselect', function(event, numFiles, label) {

        var input = $(this).parents('.input-group').find(':text'),
            log   = numFiles > 1
                ? numFiles + ' files selected'
                : label;

        if (input.length) {
            input.val(log);
        } else {
            // if (log) alert(log);
        }
    });
});

function format_price(price, symbol, position) {
    if (position == 'left') {
        return symbol + ' ' + format_thousands(price);
    }

    return format_thousands(price) + ' ' + symbol;
}

function format_thousands(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
