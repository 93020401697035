/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id            = '#page__backup_options_view';
        var __btn_generate_quote = '#js-action-generate-quote';

        var chartId             = 'chart';
        var __breakeven         = '#breakeven';
        var __breakeven_month   = '#breakeven-month';
        var __chart_data        = '#chart-data';
        var __currency_data     = '#currency-data';
        var __chart_years       = '#chart-years';
        var __chart_point_modal = '#modalChartPoint';

        var __timeline_data = '#timeline_data';

        var __table_timeline        = '#table-timeline';
        var __table_timeline_length = '#table-timeline-lenght';

        var __table_fuelcell_capex  = '#dt-table-fuelcell-capex';
        var __table_battery_capex   = '#dt-table-battery-capex';
        var __table_generator_capex = '#dt-table-generator-capex';

        var __table_fuelcell_opex  = '#dt-table-fuelcell-opex';
        var __table_battery_opex   = '#dt-table-battery-opex';
        var __table_generator_opex = '#dt-table-generator-opex';

        var __btn_prev_month = '.js-prev-month';
        var __btn_next_month = '.js-next-month';
        var __btn_pdf_gen    = '.js-btn-pdf-gen';

        var CHART;
        var BREAKEVEN       = '';
        var BREAKEVEN_MONTH = 1000;
        var CURRENCY;
        var TIMELINE_DATA;
        var CHART_DATA      = {
            fuelcell : [],
            battery  : [],
            generator: []
        };

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            __assignEvents();
            __parseChartData();

            if ($(__timeline_data).length) {
                TIMELINE_DATA = JSON.parse($(__timeline_data).val());
            } else {
                TIMELINE_DATA = [];
            }

            CURRENCY        = JSON.parse($(__currency_data).val());
            BREAKEVEN       = $(__breakeven).val();
            BREAKEVEN_MONTH = parseInt($(__breakeven_month).val());

            __initChart();
        };

        var __parseChartData = function() {
            var data = JSON.parse($(__chart_data).val());

            for (var i in data) {
                if (!data.hasOwnProperty(i)) continue;

                var x = parseInt(i) + 1;

                CHART_DATA.fuelcell.push({
                    x: x,
                    y: data[ i ].fuelcell
                });

                CHART_DATA.battery.push({
                    x: x,
                    y: data[ i ].battery
                });

                CHART_DATA.generator.push({
                    x: x,
                    y: data[ i ].generator
                });
            }
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            var self = this;

            $.fn.dataTableExt.sErrMode = "console";

            __initTableModal(__table_fuelcell_capex);
            __initTableModal(__table_battery_capex);
            __initTableModal(__table_generator_capex);

            __initTableModal(__table_fuelcell_opex);
            __initTableModal(__table_battery_opex);
            __initTableModal(__table_generator_opex);

            $(__table_timeline_length).on('change', function(e) {
                e.preventDefault();

                var length = $(this).val() * 12 - 1;

                $(__table_timeline).find('tbody tr').show();
                $(__table_timeline).find('tbody tr:gt(' + length + ')').hide();
            }).trigger('change');

            $(__chart_years).find('a').on('click', function(e) {
                e.preventDefault();

                var $this = $(this);
                var val   = $this.data('value');

                $this.closest(__chart_years).find('.btn value').html(val + ' ' + pluralize('Year', val));

                var DATA = [];

                for (var i = 0; i < val * 12; i++) {
                    DATA.push({
                        month    : (i + 1),
                        fuelcell : CHART_DATA.fuelcell[ i ].y,
                        battery  : CHART_DATA.battery[ i ].y,
                        generator: CHART_DATA.generator[ i ].y
                    });
                }

                CHART.dataProvider = DATA;
                CHART.validateData();
            });

            $(__btn_prev_month).on('click', function(e) {
                e.preventDefault();

                var month = parseInt($(__chart_point_modal).data('month'));
                month--;

                if (month < 1) {
                    month = 240;
                }

                __pointClickItemsByMonth(month);
            });

            $(__btn_next_month).on('click', function(e) {
                e.preventDefault();

                var month = parseInt($(__chart_point_modal).data('month'));
                month++;

                if (month > 240) {
                    month = 1;
                }

                __pointClickItemsByMonth(month);
            });

            $(__btn_pdf_gen).on('click', function(e) {
                e.preventDefault();

                $.ajax({
                    url       : $(this).data('href'),
                    dataType  : 'json',
                    beforeSend: function() {
                        window.addLoader(null, 'PDF is being generated. Please DO NOT Refresh this page.');
                    },
                    success   : function(response) {
                        window.location = response.pdf;
                        window.removeLoader();
                    }
                });
            });

            $(__btn_generate_quote).on('click', function(e) {
                e.preventDefault();

                var backup_option_id = $(this).data('id');

                $.ajax({
                    url       : '/backup-options/' + backup_option_id + '/quote',
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token: window.TOKEN
                    },
                    beforeSend: function() {
                        window.addLoader();
                    },
                    success   : function(response) {
                        setTimeout(function() {
                            if (response.status) {
                                window.location = response.url;
                            } else {
                                show_error();
                                window.removeLoader();
                            }
                        }, 1000);
                    }
                });
            });
        };

        var __initTableModal = function(table) {
            $(table).DataTable({
                ordering    : false,
                searching   : true,
                pageLength  : 10000,
                lengthChange: false
            });
        };

        var __initChart = function() {
            var DATA   = [];
            var length = window.chart_length || 180;

            for (var i = 0; i < length; i++) {
                DATA.push({
                    month    : (i + 1),
                    fuelcell : CHART_DATA.fuelcell[ i ].y,
                    battery  : CHART_DATA.battery[ i ].y,
                    generator: CHART_DATA.generator[ i ].y
                });
            }

            var breakPoint = [];

            if (BREAKEVEN_MONTH < 1000) {
                breakPoint.push({
                    category     : BREAKEVEN_MONTH,
                    lineColor    : "#CC0000",
                    lineAlpha    : 1,
                    dashLength   : 2,
                    position     : 'right',
                    inside       : true,
                    labelRotation: 90,
                    label        : "Break Even POINT"
                });
            }

            CHART = AmCharts.makeChart(chartId, {
                "type"                 : "serial",
                "theme"                : "light",
                "marginRight"          : 10,
                "marginLeft"           : 100,
                "autoMarginOffset"     : 20,
                "mouseWheelZoomEnabled": false,
                "legend"               : {
                    "useGraphSettings": true,
                    "valueText"       : ""
                },
                "listeners"            : [
                    {
                        "event" : "init",
                        "method": function(e) {
                            e.chart.chartDiv.addEventListener("click", function() {
                                if (e.chart.lastCursorPosition !== undefined) {
                                    var month = e.chart.dataProvider[ e.chart.lastCursorPosition ][ e.chart.categoryField ];

                                    __pointClickItemsByMonth(month);

                                    $(__chart_point_modal).modal('show');
                                }
                            })
                        }
                    },
                    {
                        "event" : "changed",
                        "method": function(e) {
                            e.chart.lastCursorPosition = e.index;
                        }
                    }
                ],
                "valueAxes"            : [
                    {
                        "id"             : "v1",
                        "axisAlpha"      : 0,
                        "position"       : "left",
                        "ignoreAxisWidth": true,
                        "unit"           : CURRENCY.code + ' ',
                        "unitPosition"   : CURRENCY.position
                    }
                ],
                "balloon"              : {
                    "borderThickness": 1,
                    "shadowAlpha"    : 0
                },
                "chartCursor"          : {
                    "cursorAlpha"            : 0.1,
                    "cursorColor"            : "#258cbb",
                    "fullWidth"              : true,
                    "graphBulletSize"        : 2,
                    "pan"                    : true,
                    "valueLineEnabled"       : true,
                    "valueLineBalloonEnabled": true,
                    "valueLineAlpha"         : 0.2,
                    "valueZoomable"          : true,
                    "categoryBalloonFunction": function(category) {
                        return parseInt(category / 12) + 'Y';
                    }
                },
                "categoryField"        : "month",
                "categoryAxis"         : {
                    "gridColor"       : "white",
                    "minHorizontalGap": 10,
                    "minorGridAlpha"  : 0,
                    "minorGridEnabled": true,
                    "labelFunction"   : function(valueText, data, categoryAxis) {
                        var month = (data.category % 12);
                        var year  = parseInt(data.category / 12);

                        if (month || year === 20) {
                            return '';
                        }

                        return year + 'Y';
                    },
                    "guides"          : breakPoint
                },
                "graphs"               : [
                    {
                        "id"                         : "g1",
                        "bullet"                     : "round",
                        "colorField"                 : "color",
                        "bulletBorderAlpha"          : 1,
                        "bulletColor"                : "#FFFFFF",
                        "bulletSize"                 : 5,
                        "hideBulletsCount"           : 50,
                        "lineThickness"              : 2,
                        "useLineColorForBulletBorder": true,
                        "title"                      : "Fuel Cell",
                        "valueField"                 : "fuelcell",
                        "lineColor"                  : "#007ba2",
                        "balloonText"                : "",
                        "balloonFunction"            : balloonFunction
                    },
                    {
                        "id"                         : "g2",
                        "bullet"                     : "round",
                        "colorField"                 : "color",
                        "bulletBorderAlpha"          : 1,
                        "bulletColor"                : "#FFFFFF",
                        "bulletSize"                 : 5,
                        "hideBulletsCount"           : 50,
                        "lineThickness"              : 1,
                        "useLineColorForBulletBorder": true,
                        "title"                      : "Batteries",
                        "valueField"                 : "battery",
                        "lineColor"                  : "#f7943d",
                        "balloonText"                : "",
                        "balloonFunction"            : balloonFunction
                    },
                    {
                        "id"                         : "g3",
                        "bullet"                     : "round",
                        "colorField"                 : "color",
                        "bulletBorderAlpha"          : 1,
                        "bulletColor"                : "#FFFFFF",
                        "bulletSize"                 : 5,
                        "hideBulletsCount"           : 50,
                        "lineThickness"              : 1,
                        "useLineColorForBulletBorder": true,
                        "title"                      : "Generator",
                        "valueField"                 : "generator",
                        "lineColor"                  : "#333",
                        "balloonText"                : "",
                        "balloonFunction"            : balloonFunction
                    }
                ],
                "dataProvider"         : DATA
            });
        };

        var balloonFunction = function(item, graph) {
            var balloon = "<span style='font-size:12px;'><span style='font-weight: bold; color: [[color]];'>[[title]]</span>: [[month]] - [[total]]</span>";

            var row    = item.dataContext;
            var amount = row[ graph.valueField ];

            var year  = parseInt(row.month / 12) + 'y';
            var month = row.month % 12 + 'm';
            var total = parseFloat(amount).toFixed(0);

            balloon = balloon.replace("[[title]]", graph.title);
            balloon = balloon.replace("[[color]]", graph.lineColor);
            balloon = balloon.replace("[[month]]", (year !== '0y' ? year : '') + month);
            balloon = balloon.replace("[[total]]", format_price(total, CURRENCY.code, CURRENCY.position));

            return balloon;
        };

        var __pointClickItemsByMonth = function(month) {
            var fuelcell  = TIMELINE_DATA.fuelcell[ month ];
            var battery   = TIMELINE_DATA.battery[ month ];
            var generator = TIMELINE_DATA.generator[ month ];

            $(__chart_point_modal).find('value.month').html(month);
            $(__chart_point_modal).find('tbody.fuelcell_items').html(__pointClickItems(fuelcell));
            $(__chart_point_modal).find('tbody.battery_items').html(__pointClickItems(battery));
            $(__chart_point_modal).find('tbody.generator_items').html(__pointClickItems(generator));
            $(__chart_point_modal).data('month', month);
        };

        var __pointClickItems = function(data) {
            var rows = '';

            if (data.items_detailed.length) {
                var current_month = 0;

                for (var i in data.items_detailed) {
                    if (!data.items_detailed.hasOwnProperty(i)) continue;

                    current_month += parseFloat(data.items_detailed[ i ].total.replace(CURRENCY.code, ''));
                }

                for (var i in data.items_detailed) {
                    if (!data.items_detailed.hasOwnProperty(i)) continue;

                    var item = data.items_detailed[ i ];

                    rows += '<tr>';

                    if (!parseInt(i)) {
                        rows += '<th class="text-center" rowspan="1000">' +
                                format_price(data.total.toFixed(0), CURRENCY.code, CURRENCY.position) +
                                '</th>';
                        rows += '<th class="text-center" rowspan="1000">' +
                                format_price(parseFloat(current_month).toFixed(0), CURRENCY.code, CURRENCY.position) +
                                '</th>';
                    }

                    var temp_total = parseFloat(item.total.replace(CURRENCY.code, ''));

                    rows += '<td>' + item.name + '</td>';
                    rows += '<td class="text-center">' +
                            format_price(temp_total.toFixed(0), CURRENCY.code, CURRENCY.position) +
                            '</td>';
                    rows += '</tr>';
                }
            } else {
                rows += '<tr>';
                rows += '<th class="text-center" rowspan="1000">' + format_price(data.total.toFixed(0), CURRENCY.code, CURRENCY.position) + '</th>';
                rows += '<th class="text-center" rowspan="1000">' + format_price('0.00', CURRENCY.code, CURRENCY.position) + '</th>';
                rows += '<td><span class="text-muted">No data</span></td>';
                rows += '<td></td>';
                rows += '</tr>';
            }

            return rows;
        };
    }).init();
})(jQuery);