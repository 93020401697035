/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id    = '#page__templates_index';
        var __table      = '#dt-table';
        var __modal_list = '#modalListItemsTemplate';

        var SEARCHES = {};
        var COLUMNS  = [
            {
                data        : 'company_name',
                title       : 'Company',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : 'name',
                title       : 'Name',
                orderable   : true,
                className   : 'text-left',
                customsearch: true,
                render      : function(data, type, row) {
                    return data +
                           '<div class="inline pull-right" data-container="body" data-trigger="hover" ' +
                           'data-toggle="popover" data-placement="bottom" data-html="true" data-content="' + nl2br(row.description) + '">' +
                           '<i class="fa fa-info-circle"></i>' +
                           '</div>';
                }
            },
            {
                data        : 'items_count',
                title       : 'Items',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                render      : function(data, type, row) {
                    return '<a href="#" ' +
                           'data-template-id="' + row.id + '" data-toggle="modal" data-target="#modalListItemsTemplate">' + data + '</a>';
                }
            },
            {
                data        : 'total_cost',
                title       : 'Total Price',
                orderable   : true,
                className   : 'text-center',
                customsearch: true,
                width       : '120px',
                render      : function(data, type, row) {
                    return format_price(data, ' ' + row.currency_code);
                }
            },
            {
                data        : 'created_by',
                title       : 'Created by',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : '',
                orderable   : false,
                className   : 'text-right',
                customsearch: false,
                width       : '160px',
                render      : function(data, type, row) {
                    var $html = '';

                    $html += ' <a href="#" class="btn btn-success btn-sm" ' +
                             'data-template-id="' + row.id + '" data-toggle="modal" data-target="#modalListItemsTemplate">' +
                             '<i class="fa fa-list" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Items"></i>' +
                             '</a>';

                    if (window.PERMISSIONS.templates__create) {
                        $html += ' <a href="/templates/' + row.id + '/clone" class="btn btn-info btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Clone Template">' +
                                 '<i class="fa fa-clone"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.templates__edit) {
                        $html += ' <a href="/templates/' + row.id + '/edit" class="btn btn-default btn-sm" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit Template">' +
                                 '<i class="fa fa-pencil"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.templates__delete) {
                        $html += ' <a href="/templates/' + row.id + '/delete" class="btn btn-danger btn-sm" ' +
                                 'data-method="post" data-confirm="Are you sure?" data-token="' + window.TOKEN + '" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="Delete Template">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            COLUMNS.forEach(function(element, index) {
                if (element.customsearch) {
                    SEARCHES[ element.data ] = '';
                }
            });

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            $(__body_id).on('show.bs.modal', __modal_list, function(e) {
                var $modal = $(this);
                var $body  = $modal.find('.modal-body');

                $body.html('');
                window.addLoader($body);
            });

            $(__body_id).on('shown.bs.modal', __modal_list, function(e) {
                var $modal      = $(this);
                var $body       = $modal.find('.modal-body');
                var $button     = $(e.relatedTarget);
                var template_id = $button.data('template-id');

                $.ajax({
                    url     : '/templates/' + template_id + '/items',
                    dataType: 'json',
                    success : function(response) {
                        var type = response.type;

                        $body.html(response.view);

                        setTimeout(function() {
                            $body.find('table')
                                 .on('length.dt', function(e, settings, len) {
                                     set_page_length(__body_id + '_items_modal', len);
                                 })
                                 .DataTable({
                                     "lengthChange": true,
                                     "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                                     "pageLength"  : get_page_length(__body_id + '_items_modal'),
                                     "filter"      : false,
                                     "ordering"    : false,
                                     "searching"   : true,
                                     "pagingType"  : 'full_numbers',
                                     initComplete  : function() {
                                         if (!$(this).find('tfoot').length) {
                                             $(this).append('<tfoot></tfoot>');
                                         }

                                         if (!$(this).find('tfoot').find('.row-filter').length) {
                                             var $footer = '<tr class="row-total">';

                                             var currency_symbol   = $(this).data('currency-symbol');
                                             var currency_position = $(this).data('currency-position');

                                             switch (type) {
                                                 case "fuelcell_capex":
                                                     var total = 0;

                                                     $(this).DataTable().rows()
                                                            .every(function(rowIdx, tableLoop, rowLoop) {
                                                                var data   = this.data();
                                                                var amount = parseInt(data[ 3 ]);
                                                                var cost   = parseFloat(data[ 2 ].replace(',', '')
                                                                                                 .replace(currency_symbol, ''));
                                                                total += (amount * cost);
                                                            });

                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td class="text-center"><strong>Total: ' + format_price(total.toFixed(0), currency_code) + '</strong></td>';
                                                     break;

                                                 case "battery_capex":
                                                 case "generator_capex":
                                                     var total = 0;

                                                     $(this).DataTable().rows()
                                                            .every(function(rowIdx, tableLoop, rowLoop) {
                                                                var data   = this.data();
                                                                var amount = parseInt(data[ 2 ]);
                                                                var cost   = parseFloat(data[ 1 ].replace(',', '')
                                                                                                 .replace(currency_symbol, ''));
                                                                total += (amount * cost);
                                                            });

                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td class="text-center"><strong>Total: ' + format_price(total.toFixed(0), currency_code) + '</strong></td>';
                                                     break;

                                                 case "fuelcell_opex":
                                                 case "battery_opex":
                                                 case "generator_opex":
                                                     var total = 0;

                                                     $(this).DataTable().rows()
                                                            .every(function(rowIdx, tableLoop, rowLoop) {
                                                                var data   = this.data();
                                                                var amount = parseInt(data[ 2 ]);
                                                                var cost   = parseFloat(data[ 1 ].replace(',', '')
                                                                                                 .replace(currency_symbol, ''));
                                                                total += (amount * cost);
                                                            });

                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td></td>';
                                                     $footer += '<td class="text-center"><strong>Total: ' + format_price(total.toFixed(0), currency_code) + '</strong></td>';
                                                     break;
                                             }

                                             $footer += '</tr>';

                                             $(this).find('tfoot').append($footer);
                                         }

                                         window.initProductHover();
                                     }
                                 });

                            setTimeout(function() {
                                window.removeLoader($body);
                            }, 200);
                        }, 100);
                    }
                });
            });

            __initTable();
        };

        var __initTable = function() {
            if (!!parseInt(window.ROLE_IS_USER)) {
                COLUMNS.shift();
            }

            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length(__body_id, len);
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-4"i>' +
                                    '<"col-md-8 text-right"<"toolbar">l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length(__body_id),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    initComplete  : function() {
                        initToolbarFilters();

                        if (!$(this).find('thead').find('.row-filter').length) {
                            var $rowFilter = '<tr class="row-filter">';

                            COLUMNS.forEach(function(element, index) {
                                $rowFilter += '<td>';

                                if (element.customsearch) {
                                    $rowFilter += '<input type="text" class="form-control fc-input-sm fc-block js-search-field" data-field="' + element.data + '">';
                                }

                                $rowFilter += '</td>';
                            });

                            $rowFilter += '</tr>';

                            $(this).find('thead').append($rowFilter);
                        }
                    },
                    ajax          : function(data, callback, settings) {
                        data.search_row = SEARCHES;
                        data.filter_row = window.FILTER_ROW;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    },
                    "order"       : [ [ 0, 'asc' ] ],
                    "columns"     : COLUMNS
                });

            $(__body_id).on('keyup', __table + ' thead .row-filter .js-search-field', function(e) {
                __collectSearches();
            });
        };

        var __collectSearches = function() {
            $(__table + ' thead .row-filter .js-search-field').each(function() {
                var $input = $(this);

                SEARCHES[ $input.data('field') ] = $input.val().trim();
            });

            setTimeout(function() {
                $(__table).DataTable().draw();
            }, 100);
        };

    }).init();

})(jQuery);