/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__price_list_index';
        var __table   = '#dt-table';

        var SEARCH_ROW = {};
        var COLUMNS    = [
            {
                data        : 'company_name',
                title       : 'Company',
                orderable   : true,
                className   : 'text-left',
                customsearch: true,
                render      : function(data, type, row) {
                    return '<span class="label label-blue label-small">' + row.currency.code + '</span> ' + data;
                }
            },
            {
                data        : 'owner_name',
                title       : 'Created by',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : 'created_at',
                title       : 'Created date',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : 'last_editor_name',
                title       : 'Edited by',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : 'updated_at',
                title       : 'Last edit date',
                orderable   : true,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : 'items_count',
                title       : 'Entries',
                orderable   : false,
                className   : 'text-center',
                customsearch: true
            },
            {
                data        : '',
                title       : 'Status',
                orderable   : false,
                customsearch: false,
                width       : '120px',
                className   : 'text-center',
                render      : function(data, type, row) {
                    if (row.is_ready) {
                        return '<div class="progress" style="margin: 0;">' +
                               '<div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;">' +
                               '100%' +
                               '</div>' +
                               '</div>';
                    }

                    var ready_percent = 25 * (row.step - 1);

                    return '<div class="progress" style="margin: 0;">' +
                           '<div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="' + ready_percent + '" aria-valuemin="0" aria-valuemax="100" style="width: ' + ready_percent + '%;">' +
                           ready_percent + '%' +
                           '</div>' +
                           '</div>';
                }
            },
            {
                data     : '',
                orderable: false,
                className: 'text-right',
                width    : '160px',
                render   : function(data, type, row) {
                    var $html = '';

                    if (row.is_ready) {
                        if (window.PERMISSIONS.price_list__view) {
                            $html += '<a href="/pricelists/' + row.id + '/view" class="btn btn-sm btn-default input-sm" ' +
                                     'data-toggle="tooltip" data-placement="bottom" title="View price list">' +
                                     '<i class="fa fa-eye"></i>' +
                                     '</a>'
                        }

                        if (window.PERMISSIONS.price_list__edit) {
                            $html += ' <a href="/pricelists/' + row.id + '/edit" class="btn btn-sm btn-default input-sm" ' +
                                     'data-toggle="tooltip" data-placement="bottom" title="Edit price list">' +
                                     '<i class="fa fa-pencil"></i>' +
                                     '</a>'
                        }
                    } else {
                        $html += '<a href="/pricelists/' + row.id + '/configure" class="btn btn-default btn-sm input-sm" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="Configure">' +
                                 '<i class="fa fa-cogs"></i> Configure' +
                                 '</a>';
                    }

                    if (window.USER_ID == row.owner_id && window.PERMISSIONS.price_list__delete) {
                        $html += ' <a href="/pricelists/' + row.id + '/delete" class="btn btn-danger btn-sm input-sm" ' +
                                 'data-method="post" data-confirm="Are you sure?" data-token="' + window.TOKEN + '" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="Delete">' +
                                 '<i class="fa fa-times"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            COLUMNS.forEach(function(element, index) {
                if (element.customsearch) {
                    SEARCH_ROW[ element.data ] = '';
                }
            });

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            __initTable();
        };

        var __initTable = function() {
            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length('price_list_index', len);
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-6"i>' +
                                    '<"col-md-6 text-right"<"toolbar">l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length('price_list_index'),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    "order"       : [ [ 0, 'asc' ] ],
                    "columns"     : COLUMNS,
                    initComplete  : function() {
                        initToolbarFilters();

                        if (!$(this).find('thead').find('.row-filter').length) {
                            var $rowFilter = '<tr class="row-filter">';

                            COLUMNS.forEach(function(element, index) {
                                $rowFilter += '<td>';

                                if (element.customsearch) {
                                    $rowFilter += '<input type="text" class="form-control fc-input-sm fc-block js-search-field" data-field="' + element.data + '">';
                                }

                                $rowFilter += '</td>';
                            });

                            $rowFilter += '</tr>';

                            $(this).find('thead').append($rowFilter);
                        }
                    },
                    ajax          : function(data, callback, settings) {
                        data.search_row = SEARCH_ROW;
                        data.filter_row = window.FILTER_ROW;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    }
                });

            $(__body_id).on('keyup', __table + ' thead .row-filter .js-search-field', function(e) {
                e.preventDefault();

                __collectSearches();
            });
        };

        var __collectSearches = function() {
            $(__table + ' thead .row-filter .js-search-field').each(function() {
                var $input = $(this);

                SEARCH_ROW[ $input.data('field') ] = $input.val().trim();
            });

            setTimeout(function() {
                $(__table).DataTable().draw();
            }, 100);
        };

    }).init();

})(jQuery);