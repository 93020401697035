/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id             = '#page__lists_index';
        var __table               = '#dt-table';
        var __tab                 = '.js-tab';
        var __btn_add_row         = '.js-add-row';
        var __btn_remove_row      = '.js-remove-row';
        var __delete_ids          = '.js-ids-to-delete';
        var __btn_import__company = '.js-import-company';
        var __btn_import__items   = '.js-import-items';
        var __select_company      = '#current_company_dropdown';

        var __modal_import_1 = '#modalImportStep1';
        var __modal_import_2 = '#modalImportStep2';

        var fields_decimal = [
            '[name^="list_item["][name$="][cost]"]'
        ];

        var fields_integer = [
            '[name^="list_item["][name$="][needed]"]',
            '[name^="list_item["][name$="][interval]"]'
        ];

        var IMPORT_COMPANY_ID = 0;

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            for (var i in fields_decimal) {
                if (!fields_decimal.hasOwnProperty(i)) continue;

                $(fields_decimal[ i ]).on('keypress', __decimalInput);
            }

            for (var j in fields_integer) {
                if (!fields_integer.hasOwnProperty(j)) continue;

                $(fields_integer[ j ]).on('keypress', __integerInput);
            }

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $(__body_id).on('click', __btn_add_row, function(e) {
                e.preventDefault();

                __addRow();
            });

            $(__body_id).on('click', __btn_remove_row, function(e) {
                e.preventDefault();

                __removeRow($(this));
            });

            $(__body_id).on('change', __select_company, function(e) {
                e.preventDefault();

                window.addLoader();
                window.location = "/lists/company/" + $(this).val();
            });

            $(__body_id).on('click', __btn_import__company, function(e) {
                e.preventDefault();

                IMPORT_COMPANY_ID = $(__modal_import_1).find('[name="company_id"]').val();

                $.ajax({
                    url       : '/lists/items/' + IMPORT_COMPANY_ID,
                    dataType  : 'json',
                    beforeSend: function() {
                        $(__modal_import_1).modal('hide');
                        window.addLoader();
                    },
                    success   : function(response) {
                        $(__modal_import_2).find('.modal-body').html(response.view);

                        setTimeout(function() {
                            if (!$(__modal_import_2).find('.modal-body').find('.no-data').length) {
                                $(__modal_import_2).find('.modal-body table').DataTable({
                                    lengthChange: false,
                                    ordering    : false,
                                    searching   : true,
                                    pageLength  : 10000,
                                    initComplete: function() {
                                        $(__modal_import_2).find('.modal-body table').css({width: '100%'});
                                    }
                                });
                            }

                            window.removeLoader();
                            $(__modal_import_2).modal('show');
                        }, 100);
                    }
                });
            });

            $(__body_id).on('click', __btn_import__items, function(e) {
                e.preventDefault();

                var $modal   = $(this).closest('.modal');
                var item_ids = $modal.find("input:checkbox:checked[name='item']").map(function() {
                    return $(this).val();
                }).get();

                $.ajax({
                    url       : '/lists/items/' + IMPORT_COMPANY_ID,
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token: window.TOKEN,
                        ids   : item_ids
                    },
                    beforeSend: function() {
                        window.addLoader();
                        $(__modal_import_2).find('.alert-danger').addClass('hidden');
                    },
                    success   : function(response) {
                        if (response.status) {
                            $(__modal_import_2).modal('hide');

                            setTimeout(function() {
                                window.location.reload();
                            }, 100);
                        } else {
                            window.removeLoader();
                            $(__modal_import_2).find('.alert-danger').removeClass('hidden');
                        }
                    }
                });
            });
        };

        /**
         ***************************************************************************
         * Add new empty row via server side
         ***************************************************************************
         */
        var __addRow = function() {
            var $html = '';

            var index    = parseInt($(__table).find('tbody tr:last-child .index').val()) + 1;
            var symbol   = $(__table).data('currency-symbol');
            var position = $(__table).data('currency-position');

            switch ($(__tab).val()) {
                case "battery_capex":
                case "generator_capex":
                    $html = '<tr>' +
                            '<td class="hidden">' +
                            '<input class="index" name="index" type="hidden" value="' + index + '">' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<input class="form-control fc-input-sm" name="list_item[' + index + '][name]" type="text">' +
                            '</div>' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<div class="input-group">' +
                            format_price('<input class="form-control fc-input-sm" name="list_item[' + index + '][cost]" type="text">', '<span class="input-group-addon">' + symbol + '</span>', position) +
                            '</div>' +
                            '</div>' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<input class="form-control fc-input-sm" name="list_item[' + index + '][needed]" type="text">' +
                            '</div>' +
                            '</td>' +
                            '<td class="text-right">' +
                            '<button class="btn btn-primary btn-sm js-remove-row" data-toggle="tooltip" data-placement="bottom" title="Delete" type="button">' +
                            '<i class="fa fa-times"></i>' +
                            '</button>' +
                            '</td>' +
                            '</tr>';
                    break;

                case "fuelcell_opex":
                case "battery_opex":
                case "generator_opex":
                    $html = '<tr>' +
                            '<td class="hidden">' +
                            '<input class="index" name="index" type="hidden" value="' + index + '">' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<input class="form-control fc-input-sm" name="list_item[' + index + '][name]" type="text">' +
                            '</div>' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group">' +
                            '<div class="input-group">' +
                            format_price('<input class="form-control fc-input-sm" name="list_item[' + index + '][cost]" type="text">', '<span class="input-group-addon">' + symbol + '</span>', position) +
                            '</div>' +
                            '</div>' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<input class="form-control fc-input-sm pull-left" style="width: 49%;" name="list_item[' + index + '][interval]" type="text">' +
                            '<select class="form-control fc-input-sm pull-left" style="width: 49%;" name="list_item[' + index + '][interval_unit]">' +
                            '<option value="month">Month</option>' +
                            '<option value="year">Year</option>' +
                            '</select>' +
                            '</div>' +
                            '</td>' +
                            '<td>' +
                            '<div class="form-group ">' +
                            '<select class="form-control fc-input-sm" name="list_item[' + index + '][is_up_front]">' +
                            '<option value="0">No</option>' +
                            '<option value="1">Yes</option>' +
                            '</select>' +
                            '</div>' +
                            '</td>' +
                            '<td class="text-right">' +
                            '<button class="btn btn-primary btn-sm js-remove-row" data-toggle="tooltip" data-placement="bottom" title="" type="button" data-original-title="Delete"><i class="fa fa-times"></i></button>' +
                            '</td>' +
                            '</tr>';
                    break;
            }

            $(__table).find('tbody').append($html);

            initBaseFunctions();
        };

        /**
         ***************************************************************************
         * Remove row
         ***************************************************************************
         */
        var __removeRow = function($btn) {
            var $id = $btn.closest('tr').find('.row-id');

            if ($id.length) {
                var IDS = JSON.parse($(__delete_ids).val());

                IDS.push($id.val());

                $(__delete_ids).val(JSON.stringify(IDS))
            }

            $btn.closest('tr').remove();
        };

        var __decimalInput = function(e) {
            if ((e.which != 45 || $(this).val().indexOf('-') != -1) && (e.which != 46 || $(this).val()
                                                                                                .indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }
        };

        var __integerInput = function(e) {
            if ((e.which != 46 || $(this).val().indexOf('.') < 0) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }
        };

    }).init();

})(jQuery);