/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__price_list_configuration__1';
        var __form    = '#step-form';

        var __input             = __form + ' .form-control';
        var __btn_delete        = __form + ' .js-btn-delete';
        var __error_empty_input = '.js-error-empty';

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $(__input).on('keyup', __validateEmptyInput);
            $(__input + '[data-col="price"]').on('keypress', __numericInput);
            $(__btn_delete).on('click', __deleteRow);
        };

        /**
         ***************************************************************************
         * Format price input
         *
         * Allow only `delete`, 48-57 (0-9) buttons and one point
         ***************************************************************************
         */
        var __numericInput = function(e) {
            if ((e.which != 46 || $(this).val().indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
                e.preventDefault();
            }
        };

        /**
         ***************************************************************************
         * Delete btn handler
         ***************************************************************************
         */
        var __deleteRow = function(e) {
            e.preventDefault();

            $(this).closest('tr').hide().remove();
        };

        /**
         ***************************************************************************
         * Validate one input
         ***************************************************************************
         */
        var __validateEmptyInput = function() {
            var $input = $(this);
            var $cell  = $input.closest('td');
            var $col   = $input.data('col');
            var value  = $input.val().trim();

            $cell.find(__error_empty_input).remove();

            if (!value.length) {
                $cell.removeClass('success').addClass('danger');
                $cell.append('<div class="text-danger text-small js-error-empty">The ' + $input.data('title') + ' field is required.</div>');
            } else {
                $cell.removeClass('danger').addClass('success');
            }
        };

    }).init();

})(jQuery);