/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id            = '#page__pdf_view';
        var __btn_generate_quote = '#js-action-generate-quote';

        var chartId       = 'chart';
        var __chart_data  = '#chart-data';
        var __chart_years = '#chart-years';

        var __table_timeline        = '#table-timeline';
        var __table_timeline_length = '#table-timeline-lenght';

        var __table_fuelcell_capex  = '#dt-table-fuelcell-capex';
        var __table_battery_capex   = '#dt-table-battery-capex';
        var __table_generator_capex = '#dt-table-generator-capex';

        var __table_fuelcell_opex  = '#dt-table-fuelcell-opex';
        var __table_battery_opex   = '#dt-table-battery-opex';
        var __table_generator_opex = '#dt-table-generator-opex';

        var CHART;
        var CHART_DATA = {
            fuelcell : [],
            battery  : [],
            generator: []
        };

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            __assignEvents();
            __parseChartData();

            setTimeout(function() {
                __initChart();
            }, 500);
        };

        var __parseChartData = function() {
            var data = JSON.parse($(__chart_data).val());

            for (var i in data) {
                if (!data.hasOwnProperty(i)) continue;

                var x = parseInt(i) + 1;
                // var x     = parseInt(index / 12) + 'y' + (index % 12) + 'm';

                CHART_DATA.fuelcell.push({
                    x: x,
                    y: data[ i ].fuelcell
                });

                CHART_DATA.battery.push({
                    x: x,
                    y: data[ i ].battery
                });

                CHART_DATA.generator.push({
                    x: x,
                    y: data[ i ].generator
                });
            }
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            var self = this;

            $.fn.dataTableExt.sErrMode = "console";

            // __initTableModal(__table_fuelcell_capex);
            // __initTableModal(__table_battery_capex);
            // __initTableModal(__table_generator_capex);
            //
            // __initTableModal(__table_fuelcell_opex);
            // __initTableModal(__table_battery_opex);
            // __initTableModal(__table_generator_opex);

            $(__table_timeline_length).on('change', function(e) {
                e.preventDefault();

                var length = $(this).val() * 240 - 1;

                $(__table_timeline).find('tbody tr').show();
                $(__table_timeline).find('tbody tr:gt(' + length + ')').hide();
            }).trigger('change');

            $(__chart_years).find('a').on('click', function(e) {
                e.preventDefault();

                var $this = $(this);
                var val   = $this.data('value');

                $this.closest(__chart_years).find('.btn value').html(val + ' ' + pluralize('Year', val));

                CHART.options.data[ 0 ].dataPoints = CHART_DATA.fuelcell.slice(0, val * 12);
                CHART.options.data[ 1 ].dataPoints = CHART_DATA.battery.slice(0, val * 12);
                CHART.options.data[ 2 ].dataPoints = CHART_DATA.generator.slice(0, val * 12);
                CHART.render();
            });

            $(__btn_generate_quote).on('click', function(e) {
                e.preventDefault();

                var backup_option_id = $(this).data('id');

                $.ajax({
                    url       : '/backup-options/' + backup_option_id + '/quote',
                    type      : 'post',
                    dataType  : 'json',
                    data      : {
                        _token: window.TOKEN
                    },
                    beforeSend: function() {
                        window.addLoader();
                    },
                    success   : function(response) {
                        setTimeout(function() {
                            if (response.status) {
                                window.location = response.url;
                            } else {
                                show_error();
                                window.removeLoader();
                            }
                        }, 1000);
                    }
                });
            });
        };

        var __initTableModal = function(table) {
            $(table).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });
        };

        var __initChart = function() {
            var fuelcell_data  = CHART_DATA.fuelcell.slice(0, 240);
            var battery_data   = CHART_DATA.battery.slice(0, 240);
            var generator_data = CHART_DATA.generator.slice(0, 240);

            CHART = new CanvasJS.Chart(chartId, {
                title           : {
                    text    : "",
                    fontSize: 30
                },
                animationEnabled: true,
                axisX           : {
                    gridColor     : "Silver",
                    tickColor     : "silver",
                    labelFormatter: function(e) {
                        var month = (e.value % 12);
                        return parseInt(e.value / 12) + 'Y ' + (month
                                ? month + 'm'
                                : '');
                    }
                },
                axisY           : {
                    gridColor: "Silver",
                    tickColor: "silver",
                    prefix   : '$'
                },
                toolTip         : {
                    shared          : true,
                    contentFormatter: function(e) {
                        var fColor = e.entries[ 0 ].dataSeries.color;
                        var bColor = e.entries[ 1 ].dataSeries.color;
                        var gColor = e.entries[ 2 ].dataSeries.color;

                        var fName = e.entries[ 0 ].dataSeries.name;
                        var bName = e.entries[ 1 ].dataSeries.name;
                        var gName = e.entries[ 2 ].dataSeries.name;

                        var fx          = e.entries[ 0 ].dataPoint.x;
                        var fYearLabel  = parseInt(fx / 12) + 'y';
                        var fMonthLabel = fx % 12 + 'm';

                        var bx          = e.entries[ 1 ].dataPoint.x;
                        var bYearLabel  = parseInt(bx / 12) + 'y';
                        var bMonthLabel = bx % 12 + 'm';

                        var gx          = e.entries[ 2 ].dataPoint.x;
                        var gYearLabel  = parseInt(gx / 12) + 'y';
                        var gMonthLabel = gx % 12 + 'm';

                        var fTotal = e.entries[ 0 ].dataPoint.y.toFixed(0);
                        var bTotal = e.entries[ 1 ].dataPoint.y.toFixed(0);
                        var gTotal = e.entries[ 2 ].dataPoint.y.toFixed(0);

                        return '<span style="color:' + fColor + '">' + fName + '</span>: ' + (fYearLabel !== '0y' ? fYearLabel : '') + fMonthLabel + ' - $' + fTotal + '<br>' +
                               '<span style="color:' + bColor + '">' + bName + '</span>: ' + (bYearLabel !== '0y' ? bYearLabel : '') + bMonthLabel + ' - $' + bTotal + '<br>' +
                               '<span style="color:' + gColor + '">' + gName + '</span>: ' + (gYearLabel !== '0y' ? gYearLabel : '') + gMonthLabel + ' - $' + gTotal;
                    }
                },
                theme           : "theme2",
                data            : [
                    {
                        type         : "line",
                        showInLegend : true,
                        lineThickness: 2,
                        name         : "Fuel Cell",
                        markerType   : "square",
                        color        : "#007ba2",
                        dataPoints   : fuelcell_data
                    },
                    {
                        type         : "line",
                        showInLegend : true,
                        lineThickness: 2,
                        name         : "Batteries",
                        markerType   : "circle",
                        color        : "#f7943d",
                        dataPoints   : battery_data
                    },
                    {
                        type         : "line",
                        showInLegend : true,
                        lineThickness: 2,
                        name         : "Generator",
                        markerType   : "triangle",
                        color        : "#333",
                        dataPoints   : generator_data
                    }
                ],
                legend          : {
                    cursor   : "pointer",
                    itemclick: function(e) {
                        e.dataSeries.visible = !(typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible);

                        CHART.render();
                    }
                }
            });

            CHART.render();
        };
    }).init();
})(jQuery);