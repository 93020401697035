/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id                          = '#page__templates_edit';
        var __table                            = '#dt-table';
        var __table_items_price_list           = '#dt-items-price-list';
        var __table_items_list_battery_capex   = '#dt-items-list-battery-capex';
        var __table_items_list_generator_capex = '#dt-items-list-generator-capex';
        var __table_items_list_opex            = '#dt-items-list-opex';

        var __input_errors = '#__errors';
        var __input_old    = '#__old';
        var __input_type   = '#template_type';

        var __btn_add_items   = '.js-action-add-items';
        var __btn_remove_item = '.js-action-remove-item';

        var __inputs  = __table + ' input';
        var __selects = __table + ' select';

        var __company_dropdown = '[name="company_id"]';

        var TYPE        = null;
        var TYPE_OBJECT = null;
        var ERRORS      = [];
        var OLD         = [];
        var COLUMNS     = [];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            TYPE   = $(__input_type).val();
            ERRORS = JSON.parse($(__input_errors).val());
            OLD    = JSON.parse($(__input_old).val());

            TYPE_OBJECT = new TEMPLATE_EDIT($(__table), OLD, ERRORS);

            switch (TYPE) {
                case "fuelcell_capex":
                    TYPE_OBJECT = new TEMPLATE_EDIT_FUELCELL_CAPEX($(__table), OLD, ERRORS);
                    break;

                case "battery_capex":
                    TYPE_OBJECT = new TEMPLATE_EDIT_BATTERY_CAPEX($(__table), OLD, ERRORS);
                    break;

                case "generator_capex":
                    TYPE_OBJECT = new TEMPLATE_EDIT_GENERATOR_CAPEX($(__table), OLD, ERRORS);
                    break;

                case "fuelcell_opex":
                case "battery_opex":
                case "generator_opex":
                    TYPE_OBJECT = new TEMPLATE_EDIT_OPEX($(__table), OLD, ERRORS);
                    break;
            }

            COLUMNS = TYPE_OBJECT.columns;

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            $(__body_id).on('click', __btn_add_items, function(e) {
                e.preventDefault();

                var $btn   = $(this);
                var $modal = $btn.closest('.modal');

                var $items = $modal.find('.modal-body').find('[name^="item_"]:checkbox:checked');

                var template_id = $modal.data('id');
                var ids         = $items
                    .map(function() {
                        return $(this).val();
                    })
                    .get();

                TYPE_OBJECT.addItems(template_id, ids, function() {
                    $modal.modal('hide');

                    $(__table).DataTable().draw();

                    setTimeout(function() {
                        TYPE_OBJECT.calcTotals();
                    }, 200);
                });
            });

            $(__body_id).on('click', __btn_remove_item, function(e) {
                e.preventDefault();

                var id          = $(this).data('id');
                var template_id = $(this).data('template-id');

                TYPE_OBJECT.removeItem(template_id, id);
            });

            $(__body_id).on('keyup', __inputs, function(e) {
                e.preventDefault();

                TYPE_OBJECT.calcTotals();
            });

            $(__body_id).on('change', __inputs, function(e) {
                e.preventDefault();

                TYPE_OBJECT.calcTotals();
            });

            $(__body_id).on('change', __selects, function(e) {
                e.preventDefault();

                TYPE_OBJECT.calcTotals();
            });

            $(__body_id).on('change', __company_dropdown, function(e) {
                $(__body_id).find('[data-target^="#modalAdd"][data-target$="ItemModal"]').hide();
            });

            __initTable();
        };

        var __initTable = function() {
            $(__table_items_price_list).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });

            $(__table_items_list_battery_capex).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });

            $(__table_items_list_generator_capex).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });

            $(__table_items_list_opex).DataTable({
                ordering  : false,
                searching : true,
                pageLength: 10
            });

            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-8">' +
                                    '<"col-md-4 text-right">' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8">' +
                                    '<"col-md-4 text-right">' +
                                    '>',
                    "lengthChange": false,
                    "pageLength"  : 10000,
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    initComplete  : function() {
                        if (!$(this).find('tfoot').length) {
                            $(this).append('<tfoot></tfoot>');
                        }

                        if (!$(this).find('tfoot').find('.row-filter').length) {
                            var $footer = '<tr class="row-total">' + TYPE_OBJECT.getFooter(this) + '</tr>';

                            $(this).find('tfoot').append($footer);

                            TYPE_OBJECT.calcTotals();
                        }
                    },

                    footerCallback: function(row, data, start, end, display) {
                        TYPE_OBJECT.calcTotals();
                    },
                    ajax          : function(data, callback, settings) {
                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    },
                    "columns"     : COLUMNS
                });
        };
    }).init();

})(jQuery);