/**
 * Created by elmar on 01/27/2017.
 */

function TEMPLATE_EDIT($datatable, old, errors) {
    this.$datatable = $datatable;
    this.columns    = [];
    this.old        = old;
    this.errors     = errors;
}

TEMPLATE_EDIT.prototype.addItems = function(template_id, ids, cb) {
    if (!ids.length) {
        cb();
        return;
    }

    $.ajax({
        url     : '/templates/' + template_id + '/items',
        type    : 'post',
        dataType: 'json',
        data    : {
            _token: Laravel.csrfToken,
            ids   : ids
        },
        complete: function() {
            cb();
        }
    });

};

TEMPLATE_EDIT.prototype.removeItem = function(template_id, id) {
    var self = this;

    $.ajax({
        url     : '/templates/' + template_id + '/items/' + id,
        type    : 'post',
        dataType: 'json',
        data    : {
            _token: Laravel.csrfToken
        },
        complete: function() {
            self.$datatable.DataTable().draw();

            setTimeout(function() {
                self.calcTotals();
            }, 100);
        }
    });
};

TEMPLATE_EDIT.prototype.getFooter = function(dt) {
    var $html = '';

    dt.api().columns().every(function() {
    });

    return $html;
};

TEMPLATE_EDIT.prototype.calcTotals = function() {
};

/**
 ***************************************************************************
 * FUELCELL CAPEX
 ***************************************************************************
 */
function TEMPLATE_EDIT_FUELCELL_CAPEX($datatable, old, errors) {
    var self = this;

    this.$datatable = $datatable;
    this.old        = old;
    this.errors     = errors;

    this.columns = [
        {
            title  : 'ID',
            data   : 'id',
            visible: false
        },
        {
            title    : 'Product number',
            data     : 'product_number',
            orderable: false,
            className: 'text-center',
            width    : '200px',
            render   : function(data, type, row) {
                return '<span data-toggle="product-hover" data-id="' + row.source_id + '" data-product-number="' + row.product_number + '">' + data + '</span>';
            }
        },
        {
            data     : 'name',
            title    : 'Item',
            orderable: false,
            className: 'text-left'
        },
        {
            data     : 'cost',
            title    : 'Price',
            orderable: false,
            className: 'text-center',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;

                return format_price(parseFloat(data).toFixed(0), ' ' + currency.code, 'right');
            }
        },
        {
            data     : 'amount',
            title    : 'Amount',
            orderable: false,
            className: 'text-center',
            width    : '50px',
            render   : function(data, type, row) {
                var amount = data;
                var error  = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'amount' ] != undefined) {
                        amount = self.old[ 'items' ][ row.id ][ 'amount' ];
                    }
                } catch (err) {
                }

                if (self.errors[ 'items.' + row.id + '.amount' ] != undefined && self.errors[ 'items.' + row.id + '.amount' ].length) {
                    var amount_error = self.errors[ 'items.' + row.id + '.amount' ][ 0 ];

                    error = '<div class="text-left"><small class="text-danger">' + amount_error + '</small></div>';
                }

                return '<input type="number" min="1" class="form-control fc-input-sm fc-block fc-item" name="items[' + row.id + '][amount]" value="' + amount + '" ' +
                       'data-cost="' + row.cost + '" />' +
                       error;
            }
        },
        {
            title    : 'Total',
            data     : 'total',
            orderable: false,
            className: 'text-center success',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;
                var total    = format_price((row.cost * row.amount).toFixed(0), ' ' + currency.code, 'right');

                return '<span class="total-per-item">' + total + '</span>';
            }
        },
        {
            data     : 'actions',
            orderable: false,
            className: 'text-right',
            width    : '35px',
            render   : function(data, type, row) {
                var $html = '';

                if (window.PERMISSIONS.templates__edit) {
                    $html += '<a href="#" class="btn btn-danger btn-sm js-action-remove-item" ' +
                             'data-template-id="' + row.template_id + '" data-id="' + row.id + '" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="Delete">' +
                             '<i class="fa fa-times"></i>' +
                             '</a>';
                }

                return $html;
            }
        }
    ];
}

TEMPLATE_EDIT_FUELCELL_CAPEX.prototype             = Object.create(TEMPLATE_EDIT.prototype);
TEMPLATE_EDIT_FUELCELL_CAPEX.prototype.constructor = TEMPLATE_EDIT_FUELCELL_CAPEX;

TEMPLATE_EDIT_FUELCELL_CAPEX.prototype.getFooter = function(dt) {
    var $html = '';

    dt.api().columns().every(function() {
        if (!this.visible()) {
            return;
        }

        var $class = '';
        var $value = '';

        if (this.dataSrc() == 'product_number') {
            $value = '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalAddFuecellCapexItemModal">' +
                     '<i class="fa fa-plus"></i> Add item(s)' +
                     '</a>';
        }

        if (this.dataSrc() == 'total') {
            var dtData           = $(dt).data();
            var currencySymbol   = dtData.currencySymbol;
            var currencyPosition = dtData.currencyPosition;

            $class = 'text-center success';
            $value = '<b>' + format_price('<span class="value">0.00</span>', currencySymbol, currencyPosition) + '</b>';
        }

        if (this.dataSrc() == 'actions') {
            $class = 'text-right';
            $value = '<button type="submit" class="btn btn-primary btn-sm"><i class="fa fa-save"></i> Update</button>';
        }

        $html += '<td class="col-' + this.dataSrc() + ' ' + $class + '">' + $value + '</td>';
    });

    return $html;
};

TEMPLATE_EDIT_FUELCELL_CAPEX.prototype.calcTotals = function() {
    var total = 0.00;

    $('.fc-item').each(function() {
        var $this = $(this);

        var total_per_item = (($this.val() || 0) * $this.data('cost'));

        var symbol   = $(this).closest('table').data('currency-symbol');
        var position = $(this).closest('table').data('currency-position');

        $this.closest('tr').find('.total-per-item').html(format_price(total_per_item.toFixed(0), symbol, position));

        total += total_per_item;
    });

    this.$datatable.find('tfoot .col-total .value').html(total.toFixed(0));
};

/**
 ***************************************************************************
 * BATTERY CAPEX
 ***************************************************************************
 */
function TEMPLATE_EDIT_BATTERY_CAPEX($datatable, old, errors) {
    var self = this;

    this.$datatable = $datatable;
    this.old        = old;
    this.errors     = errors;

    this.columns = [
        {
            title  : 'ID',
            data   : 'id',
            visible: false
        },
        {
            data     : 'name',
            title    : 'Item',
            orderable: false,
            className: 'text-left'
        },
        {
            data     : 'cost',
            title    : 'Price',
            orderable: false,
            className: 'text-center',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;

                return format_price(parseFloat(data).toFixed(0), ' ' + currency.code, 'right');
            }
        },
        {
            data     : 'amount',
            title    : 'Amount',
            orderable: false,
            className: 'text-center',
            width    : '50px',
            render   : function(data, type, row) {
                var amount = data;
                var error  = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'amount' ] != undefined) {
                        amount = self.old[ 'items' ][ row.id ][ 'amount' ];
                    }
                } catch (err) {
                }

                if (self.errors[ 'items.' + row.id + '.amount' ] != undefined && self.errors[ 'items.' + row.id + '.amount' ].length) {
                    var amount_error = self.errors[ 'items.' + row.id + '.amount' ][ 0 ];

                    error = '<div class="text-left"><small class="text-danger">' + amount_error + '</small></div>';
                }

                return '<input type="number" min="1" class="form-control fc-input-sm fc-block fc-item" name="items[' + row.id + '][amount]" value="' + amount + '" ' +
                       'data-cost="' + row.cost + '" />' +
                       error;
            }
        },
        {
            title    : 'Total',
            data     : 'total',
            orderable: false,
            className: 'text-center success',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;
                var total    = format_price((row.cost * row.amount).toFixed(0), ' ' + currency.code, 'right');

                return '<span class="total-per-item">' + total + '</span>';
            }
        },
        {
            data     : 'actions',
            orderable: false,
            className: 'text-right',
            width    : '35px',
            render   : function(data, type, row) {
                var $html = '';

                if (window.PERMISSIONS.templates__edit) {
                    $html += '<a href="#" class="btn btn-danger btn-sm js-action-remove-item" ' +
                             'data-template-id="' + row.template_id + '" data-id="' + row.id + '" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="Delete">' +
                             '<i class="fa fa-times"></i>' +
                             '</a>';
                }

                return $html;
            }
        }
    ];
}

TEMPLATE_EDIT_BATTERY_CAPEX.prototype             = Object.create(TEMPLATE_EDIT.prototype);
TEMPLATE_EDIT_BATTERY_CAPEX.prototype.constructor = TEMPLATE_EDIT_BATTERY_CAPEX;

TEMPLATE_EDIT_BATTERY_CAPEX.prototype.getFooter = function(dt) {
    var $html = '';

    dt.api().columns().every(function() {
        if (!this.visible()) {
            return;
        }

        var $class = '';
        var $value = '';

        if (this.dataSrc() == 'name') {
            $value = '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalAddBatteryCapexItemModal">' +
                     '<i class="fa fa-plus"></i> Add item(s)' +
                     '</a>';
        }

        if (this.dataSrc() == 'total') {
            var dtData           = $(dt).data();
            var currencySymbol   = dtData.currencySymbol;
            var currencyPosition = dtData.currencyPosition;

            $class = 'text-center success';
            $value = '<b>' + format_price('<span class="value">0.00</span>', currencySymbol, currencyPosition) + '</b>';
        }

        if (this.dataSrc() == 'actions') {
            $class = 'text-right';
            $value = '<button type="submit" class="btn btn-primary btn-sm"><i class="fa fa-save"></i> Update</button>';
        }

        $html += '<td class="col-' + this.dataSrc() + ' ' + $class + '">' + $value + '</td>';
    });

    return $html;
};

TEMPLATE_EDIT_BATTERY_CAPEX.prototype.calcTotals = function() {
    var total = 0.00;

    $('.fc-item').each(function() {
        var $this = $(this);

        var total_per_item = (($this.val() || 0) * $this.data('cost'));

        var symbol   = $(this).closest('table').data('currency-symbol');
        var position = $(this).closest('table').data('currency-position');

        $this.closest('tr').find('.total-per-item').html(format_price(total_per_item.toFixed(0), symbol, position));

        total += total_per_item;
    });

    this.$datatable.find('tfoot .col-total .value').html(total.toFixed(0));
};

/**
 ***************************************************************************
 * GENERATOR CAPEX
 ***************************************************************************
 */
function TEMPLATE_EDIT_GENERATOR_CAPEX($datatable, old, errors) {
    var self = this;

    this.$datatable = $datatable;
    this.old        = old;
    this.errors     = errors;

    this.columns = [
        {
            title  : 'ID',
            data   : 'id',
            visible: false
        },
        {
            data     : 'name',
            title    : 'Item',
            orderable: false,
            className: 'text-left'
        },
        {
            data     : 'cost',
            title    : 'Price',
            orderable: false,
            className: 'text-center',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;

                return format_price(parseFloat(data).toFixed(0), ' ' + currency.code, 'right');
            }
        },
        {
            data     : 'amount',
            title    : 'Amount',
            orderable: false,
            className: 'text-center',
            width    : '50px',
            render   : function(data, type, row) {
                var amount = data;
                var error  = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'amount' ] != undefined) {
                        amount = self.old[ 'items' ][ row.id ][ 'amount' ];
                    }
                } catch (err) {
                }

                if (self.errors[ 'items.' + row.id + '.amount' ] != undefined && self.errors[ 'items.' + row.id + '.amount' ].length) {
                    var amount_error = self.errors[ 'items.' + row.id + '.amount' ][ 0 ];

                    error = '<div class="text-left"><small class="text-danger">' + amount_error + '</small></div>';
                }

                return '<input type="number" min="1" class="form-control fc-input-sm fc-block fc-item" name="items[' + row.id + '][amount]" value="' + amount + '" ' +
                       'data-cost="' + row.cost + '" />' +
                       error;
            }
        },
        {
            title    : 'Total',
            data     : 'total',
            orderable: false,
            className: 'text-center success',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;
                var total    = format_price((row.cost * row.amount).toFixed(0), ' ' + currency.code, 'rigth');

                return '<span class="total-per-item">' + total + '</span>';
            }
        },
        {
            data     : 'actions',
            orderable: false,
            className: 'text-right',
            width    : '35px',
            render   : function(data, type, row) {
                var $html = '';

                if (window.PERMISSIONS.templates__edit) {
                    $html += '<a href="#" class="btn btn-danger btn-sm js-action-remove-item" ' +
                             'data-template-id="' + row.template_id + '" data-id="' + row.id + '" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="Delete">' +
                             '<i class="fa fa-times"></i>' +
                             '</a>';
                }

                return $html;
            }
        }
    ];
}

TEMPLATE_EDIT_GENERATOR_CAPEX.prototype             = Object.create(TEMPLATE_EDIT.prototype);
TEMPLATE_EDIT_GENERATOR_CAPEX.prototype.constructor = TEMPLATE_EDIT_GENERATOR_CAPEX;

TEMPLATE_EDIT_GENERATOR_CAPEX.prototype.getFooter = function(dt) {
    var $html = '';

    dt.api().columns().every(function() {
        if (!this.visible()) {
            return;
        }

        var $class = '';
        var $value = '';

        if (this.dataSrc() == 'name') {
            $value = '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalAddGeneratorCapexItemModal">' +
                     '<i class="fa fa-plus"></i> Add item(s)' +
                     '</a>';
        }

        if (this.dataSrc() == 'total') {
            var dtData           = $(dt).data();
            var currencySymbol   = dtData.currencySymbol;
            var currencyPosition = dtData.currencyPosition;

            $class = 'text-center success';
            $value = '<b>' + format_price('<span class="value">0.00</span>', currencySymbol, currencyPosition) + '</b>';
        }

        if (this.dataSrc() == 'actions') {
            $class = 'text-right';
            $value = '<button type="submit" class="btn btn-primary btn-sm"><i class="fa fa-save"></i> Update</button>';
        }

        $html += '<td class="col-' + this.dataSrc() + ' ' + $class + '">' + $value + '</td>';
    });

    return $html;
};

TEMPLATE_EDIT_GENERATOR_CAPEX.prototype.calcTotals = function() {
    var total = 0.00;

    $('.fc-item').each(function() {
        var $this = $(this);

        var total_per_item = (($this.val() || 0) * $this.data('cost'));

        var symbol   = $(this).closest('table').data('currency-symbol');
        var position = $(this).closest('table').data('currency-position');

        $this.closest('tr').find('.total-per-item').html(format_price(total_per_item.toFixed(0), symbol, position));

        total += total_per_item;
    });

    this.$datatable.find('tfoot .col-total .value').html(total.toFixed(0));
};

/**
 ***************************************************************************
 * OPEX
 ***************************************************************************
 */
function TEMPLATE_EDIT_OPEX($datatable, old, errors) {
    var self = this;

    this.$datatable = $datatable;
    this.old        = old;
    this.errors     = errors;

    this.columns = [
        {
            title  : 'ID',
            data   : 'id',
            visible: false
        },
        {
            data     : 'name',
            title    : 'Item',
            orderable: false,
            className: 'text-left'
        },
        {
            data     : 'cost',
            title    : 'Price',
            orderable: false,
            className: 'text-center',
            width    : '150px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;

                return format_price(parseFloat(data).toFixed(0), ' ' + currency.code);
            }
        },
        {
            data     : 'interval',
            title    : 'Interval',
            orderable: false,
            className: 'text-center',
            width    : '140px',
            render   : function(data, type, row) {
                var interval      = data;
                var interval_unit = row.interval_unit;
                var error         = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'interval' ] != undefined) {
                        interval = self.old[ 'items' ][ row.id ][ 'interval' ];
                    }
                } catch (err) {
                }

                try {
                    if (self.old[ 'items' ][ row.id ][ 'interval_unit' ] != undefined) {
                        interval_unit = self.old[ 'items' ][ row.id ][ 'interval_unit' ];
                    }
                } catch (err) {
                }

                var $monthSelected = interval_unit == 'month'
                    ? 'selected'
                    : '';

                var $yearSelected = interval_unit == 'year'
                    ? 'selected'
                    : '';

                if (self.errors[ 'items.' + row.id + '.interval' ] != undefined && self.errors[ 'items.' + row.id + '.interval' ].length) {
                    var interval_error = self.errors[ 'items.' + row.id + '.interval' ][ 0 ];

                    error += '<div class="text-left"><small class="text-danger">' + interval_error + '</small></div>';
                }

                if (self.errors[ 'items.' + row.id + '.interval_unit' ] != undefined && self.errors[ 'items.' + row.id + '.interval_unit' ].length) {
                    var interval_unit_error = self.errors[ 'items.' + row.id + '.interval_unit' ][ 0 ];

                    error += '<div class="text-left"><small class="text-danger">' + interval_unit_error + '</small></div>';
                }

                var $html = '';
                $html += '<div class="input-group input-group-sm input-group-40-60">';
                $html += '  <input type="number" min="1" class="form-control" name="items[' + row.id + '][interval]" value="' + interval + '">';
                $html += '  <select class="form-control" name="items[' + row.id + '][interval_unit]">';
                $html += '    <option value="month" ' + $monthSelected + '>Month(s)</option>';
                $html += '    <option value="year" ' + $yearSelected + '>Year(s)</option>';
                $html += '  </select>';
                $html += '</div>';
                $html += error;

                return $html;
            }
        },
        {
            data     : 'amount',
            title    : 'Amount',
            orderable: false,
            className: 'text-center',
            width    : '50px',
            render   : function(data, type, row) {
                var amount = data;
                var error  = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'amount' ] != undefined) {
                        amount = self.old[ 'items' ][ row.id ][ 'amount' ];
                    }
                } catch (err) {
                }

                if (self.errors[ 'items.' + row.id + '.amount' ] != undefined && self.errors[ 'items.' + row.id + '.amount' ].length) {
                    var amount_error = self.errors[ 'items.' + row.id + '.amount' ][ 0 ];

                    error = '<div class="text-left"><small class="text-danger">' + amount_error + '</small></div>';
                }

                return '<input type="number" min="1" class="form-control fc-input-sm fc-block fc-item" name="items[' + row.id + '][amount]" value="' + amount + '" ' +
                       'data-cost="' + row.cost + '" />' +
                       error;
            }
        },
        {
            data     : 'is_up_front',
            title    : 'Paid up-front',
            orderable: false,
            className: 'text-center',
            width    : '100px',
            render   : function(data, type, row) {
                var is_up_front = data;
                var error       = '';

                try {
                    if (self.old[ 'items' ][ row.id ][ 'is_up_front' ] != undefined) {
                        is_up_front = self.old[ 'items' ][ row.id ][ 'is_up_front' ];
                    }
                } catch (err) {
                }

                var $yesSelected = is_up_front == 1
                    ? 'selected'
                    : '';

                var $noSelected = is_up_front == 0
                    ? 'selected'
                    : '';

                if (self.errors[ 'items.' + row.id + '.is_up_front' ] != undefined && self.errors[ 'items.' + row.id + '.is_up_front' ].length) {
                    var is_up_front_error = self.errors[ 'items.' + row.id + '.is_up_front' ][ 0 ];

                    error = '<div class="text-left"><small class="text-danger">' + is_up_front_error + '</small></div>';
                }

                var $html = '';
                $html += '<select class="form-control fc-input-sm" name="items[' + row.id + '][is_up_front]">';
                $html += '  <option value="1" ' + $yesSelected + '>Yes</option>';
                $html += '  <option value="0" ' + $noSelected + '>No</option>';
                $html += '</select>';
                $html += error;

                return $html;
            }
        },
        {
            data     : 'total',
            title    : 'Total',
            orderable: false,
            className: 'text-center success',
            width    : '120px',
            render   : function(data, type, row) {
                var currency = row.template.company.currency;
                var total    = format_price((row.cost * row.amount).toFixed(0), ' ' + currency.code);

                return '<span class="total-per-item">' + total + '</span>';
            }
        },
        {
            data     : 'actions',
            orderable: false,
            className: 'text-right',
            width    : '35px',
            render   : function(data, type, row) {
                var $html = '';

                if (window.PERMISSIONS.templates__edit) {
                    $html += '<a href="#" class="btn btn-danger btn-sm js-action-remove-item" ' +
                             'data-template-id="' + row.template_id + '" data-id="' + row.id + '" ' +
                             'data-toggle="tooltip" data-placement="bottom" title="Delete">' +
                             '<i class="fa fa-times"></i>' +
                             '</a>';
                }

                return $html;
            }
        }
    ];
}

TEMPLATE_EDIT_OPEX.prototype             = Object.create(TEMPLATE_EDIT.prototype);
TEMPLATE_EDIT_OPEX.prototype.constructor = TEMPLATE_EDIT_OPEX;

TEMPLATE_EDIT_OPEX.prototype.getFooter = function(dt) {
    var $html = '';

    dt.api().columns().every(function() {
        if (!this.visible()) {
            return;
        }

        var $class = '';
        var $value = '';

        if (this.dataSrc() == 'name') {
            $value = '<a class="btn btn-success btn-sm" data-toggle="modal" data-target="#modalAddOpexItemModal">' +
                     '<i class="fa fa-plus"></i> Add item(s)' +
                     '</a>';
        }

        if (this.dataSrc() == 'total') {
            var dtData           = $(dt).data();
            var currencySymbol   = dtData.currencySymbol;
            var currencyPosition = dtData.currencyPosition;

            $class = 'text-center success';
            $value = '<b>' + format_price('<span class="value">0.00</span>', currencySymbol, currencyPosition) + '</b>';
        }

        if (this.dataSrc() == 'actions') {
            $class = 'text-right';
            $value = '<button type="submit" class="btn btn-primary btn-sm"><i class="fa fa-save"></i> Update</button>';
        }

        $html += '<td class="col-' + this.dataSrc() + ' ' + $class + '">' + $value + '</td>';
    });

    return $html;
};

TEMPLATE_EDIT_OPEX.prototype.calcTotals = function() {
    var total = 0.00;

    $('.fc-item').each(function() {
        var $this = $(this);

        var total_per_item = (($this.val() || 0) * $this.data('cost'));

        var symbol   = $(this).closest('table').data('currency-symbol');
        var position = $(this).closest('table').data('currency-position');

        $this.closest('tr').find('.total-per-item').html(format_price(total_per_item.toFixed(0), symbol, position));

        total += total_per_item;
    });

    this.$datatable.find('tfoot .col-total .value').html(total.toFixed(0));
};