/**
 * Created by elmar on 01/27/2017.
 */

(function($) {
    'use strict';

    (new function() {
        var __body_id = '#page__quotes_index';
        var __table   = '#dt-table';

        var SEARCH_ROW = {};
        var COLUMNS    = [
            {
                data        : 'name',
                title       : 'Name',
                orderable   : true,
                className   : 'text-left',
                customsearch: true
            },
            {
                data        : 'backup_option_id',
                title       : 'Backup option',
                orderable   : true,
                className   : 'text-left',
                customsearch: true,
                render      : function(data, type, row) {
                    return '<a href="/backup-options/' + data + '/view">' + row.backup_option_name + '</a>' +
                           '<div class="inline pull-right" data-container="body" data-trigger="hover" ' +
                           'data-toggle="popover" data-placement="bottom" data-html="true" data-content="' + nl2br(row.description) + '">' +
                           '<i class="fa fa-info-circle"></i>' +
                           '</div>';
                }
            },
            {
                data        : 'company_name',
                title       : 'Company Name',
                orderable   : true,
                className   : 'text-left',
                customsearch: true
            },
            {
                data     : 'expired_at',
                title    : 'Valid until',
                orderable: true,
                className: 'text-center',
                width    : '150px',
                render   : function(data, type, row) {
                    var $html = '<div class="text-center" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="top" data-html="true" data-content="[status]" data-original-title="" title="">' +
                                '<i class="fa fa-circle fc-[status-icon]" aria-hidden="true"></i> <br>' +
                                '<small class="text-muted fc-quicksand-bold">' + row.expired_at_label + '</small>' +
                                '</div>';

                    var status_icon = 'default';
                    var status      = 'ACTIVE';

                    switch (row.status) {
                        case "0":
                            status_icon = 'success';
                            status      = 'ACTIVE';
                            break;

                        case "1":
                            status_icon = 'danger';
                            status      = 'EXPIRED';
                            break;

                        case "2":
                            status_icon = 'default';
                            status      = 'PROCESSED';
                            break;
                    }

                    return $html.replace('[status-icon]', status_icon).replace('[status]', status);
                }
            },
            {
                data        : '',
                orderable   : false,
                className   : 'text-right col-actions-80',
                customsearch: false,
                width       : '100px',
                render      : function(data, type, row) {
                    var $html = '';

                    if (row.request_status == 'new') {
                        $html += ' <a href="/quotes/' + row.id + '/official-request" class="btn btn-danger btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Official Request">' +
                                 '<i class="fa fa-bell"></i>' +
                                 '</a>';
                    } else if (row.request_status == 'done') {
                        $html += ' <a href="/quotes/' + row.id + '/official-request" class="btn btn-danger btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Official Request">' +
                                 '<i class="fa fa-bell"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.quotes__view) {
                        $html += ' <a href="/quotes/' + row.id + '/view" class="btn btn-success btn-sm"' +
                                 ' data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Quote">' +
                                 '<i class="fa fa-eye"></i>' +
                                 '</a>';
                    }

                    if (window.PERMISSIONS.quotes__delete) {
                        $html += ' <a href="/quotes/' + row.id + '/delete" class="btn btn-danger btn-sm" ' +
                                 'data-method="post" data-confirm="Are you sure?" data-token="' + window.TOKEN + '" >' +
                                 '<i class="fa fa-times" ' +
                                 'data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Delete Quote"></i>' +
                                 '</a>';
                    }

                    return $html;
                }
            }
        ];

        /**
         ***************************************************************************
         * Init
         ***************************************************************************
         */
        this.init = function() {
            if (!$(__body_id).length) {
                return false;
            }

            COLUMNS.forEach(function(element, index) {
                if (element.customsearch) {
                    SEARCH_ROW[ element.data ] = '';
                }
            });

            __assignEvents();
        };

        /**
         ***************************************************************************
         * Events
         ***************************************************************************
         */
        var __assignEvents = function() {
            $.fn.dataTableExt.sErrMode = "console";

            __initTable();
        };

        var __initTable = function() {
            if (!!parseInt(window.ROLE_IS_USER)) {
                COLUMNS = COLUMNS.filter(function(item) {
                    return item.data !== 'company_name';
                });
            }

            $(__table)
                .on('processing.dt', function(e, settings, processing) {
                    if (processing) {
                    } else {
                        initBaseFunctions();
                    }
                })
                .on('length.dt', function(e, settings, len) {
                    set_page_length(__body_id, len);
                })
                .DataTable({
                    "dom"         : '<"row"' +
                                    '<"col-md-3"i>' +
                                    '<"col-md-9 text-right"<"toolbar">l>' +
                                    '>' +
                                    '<"row dt-table"' +
                                    '<"col-md-12"tr>' +
                                    '>' +
                                    '<"row"' +
                                    '<"col-md-8"i>' +
                                    '<"col-md-4 text-right"p>' +
                                    '>',
                    "lengthChange": true,
                    "lengthMenu"  : [ [ 10, 25, 50, 100, 250, 500 ], [ 10, 25, 50, 100, 250, 500 ] ],
                    "pageLength"  : get_page_length(__body_id),
                    "filter"      : false,
                    "searching"   : false,
                    "pagingType"  : 'full_numbers',
                    "processing"  : true,
                    "serverSide"  : true,
                    "order"       : [ [ 0, 'asc' ] ],
                    "columns"     : COLUMNS,
                    initComplete  : function() {
                        initToolbarFilters();

                        if (!$(this).find('thead').find('.row-filter').length) {
                            var $rowFilter = '<tr class="row-filter">';

                            COLUMNS.forEach(function(element, index) {
                                $rowFilter += '<td>';

                                if (element.customsearch) {
                                    $rowFilter += '<input type="text" class="form-control fc-input-sm fc-block js-search-field" data-field="' + element.data + '">';
                                }

                                $rowFilter += '</td>';
                            });

                            $rowFilter += '</tr>';

                            $(this).find('thead').append($rowFilter);
                        }
                    },
                    ajax          : function(data, callback, settings) {
                        data.search_row = SEARCH_ROW;
                        data.filter_row = window.FILTER_ROW;

                        $.ajax({
                            url    : window.location.href,
                            data   : data,
                            success: function(response) {
                                callback(response);
                            }
                        });
                    }
                });

            $(__body_id).on('keyup', __table + ' thead .row-filter .js-search-field', function(e) {
                __collectSearches();
            });
        };

        var __collectSearches = function() {
            $(__table + ' thead .row-filter .js-search-field').each(function() {
                var $input = $(this);

                SEARCH_ROW[ $input.data('field') ] = $input.val().trim();
            });

            setTimeout(function() {
                $(__table).DataTable().draw()
            }, 100);
        };

    }).init();

})(jQuery);