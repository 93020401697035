/**
 * Created by elmar on 01/17/2017.
 */

function initBaseFunctions() {
    try {
        $('[data-toggle="tooltip"]').tooltip();
    } catch (err) {
    }

    try {
        $('[data-toggle="popover"]').popover();
    } catch (err) {
    }

    try {
        $('select.select2').select2();
    } catch (err) {
    }

    try {
        $(document).on('click', function(e) {
            $('[data-toggle="popover"],[data-original-title]').each(function() {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover')
                                                                                       .has(e.target).length === 0) {
                    (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false  // fix for BS 3.3.6
                }
            });
        });

        window.initProductHover = function() {
            $('[data-toggle="product-hover"]').each(function() {
                var $this = $(this);

                $this.popover({
                    container: 'body',
                    placement: 'bottom',
                    trigger  : 'click',
                    html     : true,
                    content  : function() {
                        return '<i class="fa fa-spin fa-spinner"></i> Loading';
                    }
                });
            });

            $('[data-toggle="product-hover"]').on('inserted.bs.popover', function() {
                var $this           = $(this);
                var ariaDescribedby = $this.attr('aria-describedby');
                var $popover        = $('#' + ariaDescribedby);

                $popover.addClass('price-list-item-popover');

                setTimeout(function() {
                    $.ajax({
                        url    : '/pricelists/info/' + $this.data('product-number'),
                        error  : function(xhr, ajaxOptions, thrownError) {
                            $popover.find('.popover-content')
                                    .html('<div class="alert alert-danger" style="margin: 0;">Error</div>');
                        },
                        success: function(response) {
                            $popover.find('.popover-content').html(response);
                        }
                    });
                }, 100);
            });
        };

        window.initProductHover();
    } catch (err) {
    }

    try {
        window.laravel.initialize();
    } catch (err) {
    }

    $('body').on('keypress', '[data-keyup="decimal"]', function(e) {
        if ((e.which != 45 || $(this).val().indexOf('-') != -1) && (e.which != 46 || $(this).val()
                                                                                            .indexOf('.') != -1) && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }
    });
}

(function($) {
    initBaseFunctions();

    $('body').on('submit', '.js-form-ajax', function(e) {
        e.preventDefault();
        var $form = $(this);

        window.submitFormAjax($form);
    });
})(jQuery);

window.addLoader = function($target, text) {
    text = text ? '<div class="fc_spinner_text">' + text + '</div>' : '';

    ($target || $('body')).append($('<div class="fc_loader"></div>').html('<div class="fc_spinner"></div>' + text));
};

window.removeLoader = function($target) {
    ($target || $('body')).find('.fc_loader').remove();
};

window.submitFormAjax = function($form) {
    var inputs = $form.serializeArray();
    var data   = {};

    for (var i in inputs) {
        if (!inputs.hasOwnProperty(i)) continue;

        data[ inputs[ i ].name ] = inputs[ i ].value;
    }

    $.ajax({
        url       : $form.attr('action'),
        type      : $form.attr('method'),
        data      : data,
        dataType  : 'json',
        beforeSend: function() {
            window.addLoader($form);

            $form.find('.alert.alert-danger.form-errors').remove();

            var $formGroup = $form.find('.form-group');

            $formGroup.removeClass('has-error');
            $formGroup.find('.help-block').remove();
        },
        complete  : function() {
            setTimeout(function() {
                window.removeLoader($form);
            }, 200);
        },
        success   : function(response) {
            if (response.status) {
                if (response.action == 'redirect') {
                    window.location.href = response.redirect_url;

                    return;
                }

                console.error('ERROR ACTION', response);
            }

            console.error('ERROR SUBMITTING', response);
        },
        error     : function(xhr, ajaxOptions, thrownError) {
            var response = xhr.responseJSON;

            if (response.errors) {
                for (var i in Object.keys(response.errors)) {
                    if (!Object.keys(response.errors).hasOwnProperty(i)) continue;

                    var field   = Object.keys(response.errors)[ i ];
                    var message = response.errors[ field ];

                    var $input     = $form.find('[name="' + field + '"]');
                    var $formGroup = $input.closest('.form-group');
                    var $helpBlock = $formGroup.find('.help-block');

                    if (!$helpBlock.length) {
                        $formGroup.append('<p class="help-block"></p>');
                        $helpBlock = $formGroup.find('.help-block');
                    }

                    $formGroup.addClass('has-error');
                    $helpBlock.html(message);
                }
            } else if (response.message) {
                $form.append('<div class="alert alert-danger form-errors">' + response.message + '</div>')
            } else {
                var fields = Object.keys(data);

                for (var i in fields) {
                    if (!fields.hasOwnProperty(i)) continue;
                    if (!response[ fields[ i ] ]) continue;

                    var field   = fields[ i ];
                    var message = response[ fields[ i ] ][ 0 ];

                    var $input     = $form.find('[name="' + field + '"]');
                    var $formGroup = $input.closest('.form-group');
                    var $helpBlock = $formGroup.find('.help-block');

                    if (!$helpBlock.length) {
                        $formGroup.append('<p class="help-block col-sm-10 col-sm-offset-2"></p>');
                        $helpBlock = $formGroup.find('.help-block');
                    }

                    $formGroup.addClass('has-error');
                    $helpBlock.html(message);
                }
            }
        }
    });
};